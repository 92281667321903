import { axiosInstance } from './Interceptor';

export const getFarmers = async (page = 1, search = '') => {
    try {
        const response = await axiosInstance.get(`farmers?page=${page}&search=${search}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const getFarmersName = async () => {
    try {
        const response = await axiosInstance.get(`farmers?onlyNames=true`);
        return response.entities || [];
    } catch (err) {
        throw err;
    }
}

export const getFarmer = async (id) => {
    try {
        const response = await axiosInstance.get(`farmers/${id}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const deleteFarmer = async (id) => {
    try {
        const response = await axiosInstance.delete(`farmers/${id}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const editFarmer = async (id, data) => {
    try {
        const response = await axiosInstance.put(`farmers/${id}`, data);
        return response;
    } catch (err) {
        throw err;
    }
}

export const createFarmer = async (data) => {
    try {
        const response = await axiosInstance.post(`farmers`, data);
        return response;
    } catch (err) {
        throw err;
    }
}

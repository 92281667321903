import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faBars } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

import './Header.css';
import { logout } from '../../../helpers/Auth';
import logo from '../../../assets/logo.svg';

export const CustomHeader = ({ email, dispatch, sidebarDispatch }) => {
    const [loggingOut, setLogginOut] = useState(false);

    const handleLogout = async () => {
        setLogginOut(true);
        try {
            await logout();
            dispatch({
                type: "LOGOUT"
            });
        } catch (err) {
            dispatch({
                type: "LOGOUT"
            });
        }
    }

    return (
        <Navbar className="shadow" bg="light" variant="light" fixed="top">
            <img className="navbar-logo" alt="colatte-logo" src={logo}/>
            <Navbar.Brand href="#home" className="navbar-title">
                Sistema de Coleta
            </Navbar.Brand>
            <Navbar.Brand className="sidebar-toggle" onClick={() => sidebarDispatch({type: "TOGGLE"})}><FontAwesomeIcon icon={faBars}></FontAwesomeIcon></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <NavDropdown
                        title={
                            loggingOut
                                ?
                                <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
                                :
                                (email || '')
                        }
                        className="dropdown-menu-right" id="basic-nav-dropdownm">
                        <NavDropdown.Item onClick={async () => await handleLogout()}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
import React, { useState } from "react";
import InputMask from 'react-input-mask';
import { BANKS } from "../../../helpers/banks"
import { cpf, cnpj } from 'cpf-cnpj-validator';

export const FarmerAccount = ({ upValidation, entity, setEntity }) => {
  const [third, setThird] = useState(true);
  const [check, setCheck] = useState(false);
  const [docVal, setDocVal] = useState("form-control");

  const checkDoc = (e) => {
    if (e.target.value.trim().length <= 14 && !cpf.isValid(e.target.value)) {
      upValidation( { id: "Favorecido", status: false });
      setDocVal("form-control is-invalid");
    } else if (e.target.value.trim().length > 14 && !cnpj.isValid(e.target.value)) {
      upValidation( { id: "Favorecido", status: false });
      setDocVal("form-control is-invalid");
    } else {
      upValidation( { id: "Favorecido", status: true });
      setDocVal("form-control");
    }
  }

  const getBanks = () => BANKS.map(item => 
  <option key={item.cod} value={item.cod}>{`${item.cod} - ${item.name}`}</option>)
  
  return (
    <div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="validationBank">Banco</label>
          <select
            value={(entity.bankAcc || {}).bankCode || ""}
            onChange={(e) =>
              setEntity({ ...entity, bankAcc: { ...entity.bankAcc, bankCode: e.target.value } })
            }
            required={check}
            className="custom-select"
            id="validationBank"
          >
            <option value="" disabled>
              Selecione...
            </option>
            {getBanks()}
          </select>
          <div className="invalid-feedback">Selecione um Banco.</div>
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-2">
          <label htmlFor="inputAgencyCode" className="">
            Agência
          </label>
          <InputMask
            value={(entity.bankAcc || {}).agencyCode || ""}
            onChange={(e) => {
              setEntity({ ...entity, bankAcc: { ...entity.bankAcc, agencyCode: e.target.value } });
              setCheck(e.target.value.length > 0 ? true : false);
            }}
            required={check}
            maskChar={null}
            mask={"999999"}
            type="text"
            id="inputAgencyCode"
            className="form-control"
            placeholder="Ag"
          />
        </div>
        <div className="form-group col-md-1">
          <label htmlFor="validationAccountType">Tipo</label>
          <select
            value={(entity.bankAcc || {}).accountType || ""}
            onChange={(e) =>
              setEntity({ ...entity, bankAcc: { ...entity.bankAcc, accountType: e.target.value } })
            }
            required={check}
            className="custom-select"
            id="validationAccountType"
          >
            <option value="" disabled>
            </option>
            <option>CC</option>
            <option>CP</option>
          </select>
          <div className="invalid-feedback">Selecione um Tipo de Conta.</div>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputAccountNum" className="">
            Conta
          </label>
          <InputMask
            value={(entity.bankAcc || {}).accountNum || ""}
            onChange={(e) => {
              setEntity({ ...entity, bankAcc: { ...entity.bankAcc, accountNum: e.target.value } });
              e.target.value.length > 0 ? setCheck(true) : setCheck(false);
            }}
            required={check}
            maskChar={null}
            mask={"999999999999999"}
            type="text"
            id="inputAccountNum"
            className="form-control"
            placeholder="Número"
          />
        </div>
      </div>
      <div className="form-row justify-content-md-center mt-3 mb-3">
        <div className="custom-control custom-checkbox">
          <input
            onChange={() => setThird(!third)}
            className="custom-control-input"
            type="checkbox"
            id="otherPersonAccount"
          />
          <label className="custom-control-label" htmlFor="otherPersonAccount">
            Conta de Terceiros?
          </label>
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="inputAccountName" className="">
            Favorecido
          </label>
          <input
            value={((entity.bankAcc || {}).accountName || "").toUpperCase()}
            onChange={(e) => setEntity({ ...entity, bankAcc: { ...entity.bankAcc, accountName: e.target.value } })}
            required={!third}
            disabled={third}
            type="text"
            id="inputAccountName"
            className="form-control"
            placeholder="Nome"
          />
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="inputAccountDoc" className="">
            Documento
          </label>
          <InputMask
            mask="999.999.999-99"
            value={(entity.bankAcc || {}).accountDoc || ""}
            onChange={(e) => {
              setEntity({ ...entity, bankAcc: { ...entity.bankAcc, accountDoc: e.target.value }})
              checkDoc(e);
            }
            }
            required={!third}
            disabled={third}
            type="text"
            id="inputAccountDoc"
            className={docVal}
            placeholder="Número"
          ></InputMask>
        </div>
      </div>
    </div>
  );
};

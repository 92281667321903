import React, { useEffect, useState } from 'react';
import {
    useParams
} from "react-router-dom";

import { getUser, editUser } from '../../../../helpers/User';
import { Loading } from '../../../dumb/Loading/Loading';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const EditUser = () => {
    const [entity, setEntity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);
    let { id } = useParams();

    useEffect(() => {
        const handleGetUser = async () => {
            try {
                const response = await getUser(id);
                setEntity(response);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        (async () => {
            await handleGetUser();
        })();

        return () => { };
    }, [id]);


    const handleSubmit = async (evt) => {
        evt.preventDefault();
        setLoadingSave(true);
        try {
            await editUser(id, entity);
            setLoadingSave(false);
        } catch (error) {
            setLoadingSave(false);
        }
    }

    return (
        loading ?
            <Loading size="2x"></Loading>
            :
            (entity ?
                (
                    <div className="page-container">
                        <h3>Editar usuário</h3>
                        <form onSubmit={async (evt) => !loadingSave && await handleSubmit(evt)} className="card-style">
                            <label htmlFor="inputName" className="">Nome</label>
                            <input
                                value={(entity.name || '').toUpperCase()} onChange={e => setEntity({ ...entity, name: e.target.value })}
                                type="text" id="inputName" className="form-control" placeholder="Nome" required={true} autoFocus="" />
                            <label htmlFor="inputEmail" className="mt-3">Email</label>
                            <input
                                value={entity.email || ''} onChange={e => setEntity({ ...entity, email: e.target.value })}
                                type="email" id="inputEmail" className="form-control" placeholder="Email" required={true} autoFocus="" />

                            <label htmlFor="inputPassword" className="mt-3">Senha (deixe em branco para não alterar)</label>
                            <input
                                value={entity.password || ''} onChange={e => setEntity({ ...entity, password: e.target.value })}
                                type="password" id="inputPassword" className="form-control" placeholder="Senha" required={false} autoFocus="" />


                            <div className="form-check form-check-inline mt-3">
                                <input
                                    onChange={e => setEntity({ ...entity, level: e.target.value })}
                                    className="form-check-input" type="radio" name="exampleRadios" id="adminLevelRadio" value="admin" checked={entity.level === 'admin'} />
                                <label className="form-check-label" htmlFor="adminLevelRadio">
                                    Admin
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    onChange={e => setEntity({ ...entity, level: e.target.value })}
                                    className="form-check-input" type="radio" name="exampleRadios" id="userLevelRadio" value="manager" checked={entity.level === 'manager'} />
                                <label className="form-check-label" htmlFor="userLevelRadio">
                                    Gerente
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                onChange={e => setEntity({ ...entity, level: e.target.value })}
                                className="form-check-input" type="radio" name="exampleRadios" id="userLevelRadio" value="user" checked={entity.level === 'user'} />
                            <label className="form-check-label" htmlFor="userLevelRadio">
                                Usuário
                        </label>
                        </div>


                            <button disabled={loadingSave} className="btn btn-lg btn-primary btn-block mt-4 col-sm-12 col-md-4" type="submit">
                                {
                                    loadingSave ?
                                        <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
                                        :
                                        'Salvar'
                                }
                            </button>
                        </form>
                    </div>
                ) : <h4>Usuário não encontrado</h4>)
    )
}

import {axiosInstance} from './Interceptor';

export const getTruckdrivers = async (page = 1, search = '') => {
  try {
    const response = await axiosInstance.get(
      `truckdriver?page=${page}&search=${search}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getAllTruckDrivers = async () => {
  try {
    const response = await axiosInstance.get(`truckdriver?onlyNames=true`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getTruckdriver = async (id) => {
  try {
    const response = await axiosInstance.get(`truckdriver/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteTruckdriver = async (id) => {
  try {
    const response = await axiosInstance.delete(`truckdriver/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const editTruckdriver = async (id, data) => {
  try {
    const response = await axiosInstance.put(`truckdriver/${id}`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const createTruckdriver = async (data) => {
  try {
    const response = await axiosInstance.post(`truckdriver`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

import React, { useReducer } from 'react';
import { createBrowserHistory } from 'history';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './components/smart/Login/Login';
import { Dashboard } from './components/smart/Dashboard/Dashboard';
import { initialAuthState, authReducer, AuthContext } from './helpers/Auth';
import { Router, Route, Redirect } from 'react-router-dom';

export const history = createBrowserHistory();

function App() {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch
      }}
    >

      <Router history={history}>
        {
          state ?
            (
              state.token
                ?
                // Add restricted route scheme
                <div className="App">
                  <Route exact path="/">
                    <Redirect to="/portal" />
                    {/* <Dashboard email={state.email} level={state.level} dispatch={dispatch} history={history} /> */}
                  </Route>
                  <Route path="/portal">
                    <Dashboard email={state.email} level={state.level} dispatch={dispatch} history={history} />
                  </Route>
                  {/* <Route exact path="portal">
                    <Dashboard email={state.email} level={state.level} dispatch={dispatch} history={history} />
                  </Route> */}
                  {/* <Route path="/portal/*">
                    <Dashboard email={state.email} level={state.level} dispatch={dispatch} history={history} />
                  </Route> */}
                  <Route path="/login">
                    <LoginPage dispatch={dispatch} />
                  </Route>
                  {/* <Redirect to="/portal" /> */}
                </div>
                :

                // Add unrestricted routes
                <div className="App">
                  <Route path="/login">
                    <LoginPage dispatch={dispatch} />
                  </Route>
                  <Redirect to='/login' />
                </div>
            )
            :
            null
        }
      </Router>
    </AuthContext.Provider>
  );
}

export default App;

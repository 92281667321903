import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getUsers, deleteUser } from '../../../helpers/User';
import { Loading } from '../../dumb/Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationModal } from '../../dumb/ConfirmationModal/ConfirmationModal';
import { PaginationBar } from '../../dumb/PaginationBar/PaginationBar';
import { SearchBar } from '../../dumb/SearchBar/SearchBar';

export const Users = () => {
    // pagination config
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [tempSearch, setTempSearch] = useState('');
    const [totalItems, setTotalItems] = useState(null);
    const [search, setSearch] = useState('');
    useEffect(() => {
        (async () => {
            if (page) {
                await handleGetUsers(page, search);
            }
        })();
    }, [page, search]);

    const [entities, setEntities] = useState(null);
    const [loading, setLoading] = useState(false);
    const [entityToDelete, setEntityToDelete] = useState({});
    const [loadingDelete, setLoadingDelete] = useState(false);
    let history = useHistory();
    let { path } = useRouteMatch();

    const handleGetUsers = async (page, search) => {
        setLoading(true);
        try {
            const {entities, totalItems, itemsPerPage} = await getUsers(page, search);
            setEntities(entities);
            setTotalItems(totalItems);
            setItemsPerPage(itemsPerPage)
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

  const handleDelete = async (id) => {
    try {
      setLoadingDelete(true);
      await deleteUser(id);
      await handleGetUsers();
      setLoadingDelete(false);
    } catch (error) {
      setLoadingDelete(false);
    }
  };

    const handleSearch = (evt) => {
        evt.preventDefault();
        setPage(1);
        setSearch(tempSearch);
    }

    return (
            <div className="page-container">
                <h3>Usuários</h3>
                <div className="users-header text-right mb-4">
                    <button className="btn btn-primary" onClick={() => history.push(`${path}/criar`)}>Criar usuário</button>
                </div>
                <div className="card-style">
                {
                loading ?
                <Loading size="2x"></Loading>
                :
                    <div>
                        <SearchBar tempSearch={tempSearch} handleSearch={handleSearch} setSearch={(evt) => setTempSearch(evt.target.value)}></SearchBar>
                        <table className="table table-striped table-bordered mt-4">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Permissão</th>
                                    <th scope="col" className="text-center">Remover</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (entities || []).map(entity => <tr className="clickable-row" key={entity._id}>
                                        <td onClick={() => history.push(`${path}/${entity._id}`)}>{entity.name}</td>
                                        <td onClick={() => history.push(`${path}/${entity._id}`)}>{entity.email}</td>
                                        <td onClick={() => history.push(`${path}/${entity._id}`)}>{entity.level}</td>
                                        <td className="text-center no-hover">
                                            {
                                                loadingDelete && entityToDelete._id === entity._id ? 
                                                <Loading size="1x"></Loading>
                                                :
                                                <FontAwesomeIcon
                                                className="delete-button"
                                                icon={faTrash}
                                                data-toggle="modal"
                                                data-target="#confirmationModal"
                                                onClick={() => setEntityToDelete(entity)}
                                            ></FontAwesomeIcon>}
                                        </td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                        <PaginationBar page={page} setPage={setPage} totalItems={totalItems} itemsPerPage={itemsPerPage}></PaginationBar>
                    </div>
                }
                </div>
                <ConfirmationModal
                    title={`Deletar usuário ${entityToDelete && entityToDelete.name}?`}
                    message="Esta ação não pode ser revertida"
                    action={async () => await handleDelete(entityToDelete._id)}>
                </ConfirmationModal>
            </div>
    )
}

import React, {useState, useEffect} from 'react';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './Config.css';
import {getConfig, updateConfig} from '../../../helpers/Config';
import {Loading} from '../../dumb/Loading/Loading';

export const Config = () => {
  const [entity, setEntity] = useState({});
  const [firstSetup, setFirstSetup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [usernameEdited, setUsernameEdited] = useState(false);

  // get current config on initialization
  useEffect(() => {
    const handleGetConfig = async () => {
      try {
        const response = await getConfig();
        setEntity(response);
        setLoading(false);

        if (!response.sigsifUsername) {
          setFirstSetup(true);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    (async () => {
      await handleGetConfig();
    })();

    return () => {};
  }, []);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoadingSave(true);
    try {
      await updateConfig(entity);
      setLoadingSave(false);
    } catch (error) {
      setLoadingSave(false);
    }
  };

  return (
    <div className="page-container">
      <h3>Configurações</h3>
      {loading ? (
        <Loading size="2x"></Loading>
      ) : (
        <form
          onSubmit={async (evt) => !loadingSave && (await handleSubmit(evt))}
          className="card-style">
          <label htmlFor="inputName" className="">
            Usuário SIG-SIF
          </label>
          <input
            value={entity.sigsifUsername || ''}
            onChange={(e) => {
              setUsernameEdited(true);
              setEntity({...entity, sigsifUsername: e.target.value});
            }}
            type="text"
            id="inputName"
            className="form-control"
            placeholder="Usuário"
            required={true}
            autoFocus=""
          />

          <label htmlFor="inputPassword" className="mt-3">
            Senha SIG-SIF{' '}
            {firstSetup ? null : '(deixe em branco para não alterar)'}
          </label>
          <input
            value={entity.sigsifPassword || ''}
            onChange={(e) =>
              setEntity({...entity, sigsifPassword: e.target.value})
            }
            type="password"
            id="inputPassword"
            className="form-control"
            placeholder="******"
            required={usernameEdited}
            autoFocus=""
          />
          {!firstSetup && usernameEdited ? (
            <p className="password-warning">
              Senha obrigatória, pois nome de usuário foi editado
            </p>
          ) : null}

          <label htmlFor="inputName" className="mt-3">
            Número SIG-SIF
          </label>
          <input
            value={(entity.sigsifIdentifier || '').toUpperCase()}
            onChange={(e) =>
              setEntity({...entity, sigsifIdentifier: e.target.value})
            }
            type="text"
            id="inputIdentifier"
            className="form-control"
            placeholder="Número"
            required={true}
            autoFocus=""
          />

          <button
            disabled={loadingSave}
            className="btn btn-lg btn-primary btn-block mt-4 col-sm-12 col-md-4"
            type="submit">
            {loadingSave ? (
              <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
            ) : (
              'Salvar'
            )}
          </button>
        </form>
      )}
    </div>
  );
};

import axios from 'axios';
import {history} from '../App';
import {messageToast} from '../components/dumb/MessageToast/MessageToast';

export const axiosInstance = axios.create({
  baseURL: '/api/',
});

// Auth interceptors (include token in requests)
const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled
    ? false
    : true;
};

const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    request.headers['Authorization'] = `Bearer ${localStorage.getItem(
      'token',
    )}`;
  }
  return request;
};

axiosInstance.interceptors.request.use((request) => requestHandler(request));

// Response and error interceptors
const errorHandler = async (error) => {
  if (error.data && error.data.hideToast) {
    // do nothing
  } else if (error.status === 401) {
    localStorage.clear();
    // Timeout to avoid component unmounted errors
    setTimeout(() => history.push('/login'), 100);

    messageToast('Seu token expirou', 'info');
  } else if (!error.hideToast) {
    const {data} = error;
    if (data && data.msg) {
        messageToast(data.msg, 'error');
    } else {
        messageToast('Houve um erro, tente novamente', 'error');
    }
  }

  throw error;
};

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }
  if (response && response.msg) {
    messageToast(response.msg, 'success');
  }
  return response;
};

axiosInstance.interceptors.response.use(
  (response) => successHandler(response.data),
  async (error) => await errorHandler(error.response),
);

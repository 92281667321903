import axios from 'axios';

export const getCities = async (uf) => {

    try {
        const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);
        return response.data;
    } catch (err) {
        throw err;
    }
}
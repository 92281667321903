import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { UFS } from "../../../helpers/Ufs"
import { getCities } from "../../../helpers/Cities"

export const FarmerAddress = ({ upValidation, entity, setEntity }) => {
  const [cepVal, setCepVal] = useState({ className: 'form-control', required: false })
  const [ufArr, setUfArr] = useState([]);
  const [cities, setCities] = useState([]);

  const handleInputChange = (e, property) => {
    setEntity({...entity, address: { ...entity.address, [property]: (e.target.value || "").toUpperCase()}
    });
  }

  const checkCep = (cep) => {
    if (cep.length < 1) {
      upValidation({ id: "CEP", status: true });
      setCepVal({ required: false, className: 'form-control' });
    } else if (cep.length > 0 && cep.length < 8) {
      upValidation({ id: "CEP", status: false });
      setCepVal({ required: true, className: 'form-control is-invalid' });
    } else {
      upValidation({ id: "CEP", status: true });
      setCepVal({ required: true, className: 'form-control' });
    }
  }

  const fetchUfId = (estate) => {
    let ufId = '';
    UFS.forEach(item => {
      if (item.sigla === estate) {
        ufId = item.id;
      }
    });
    return ufId;
  }

  const fetchCityId = (city) => {
    let cityId = '';
      cities.forEach(item => {
        if((item.nome || "").toUpperCase() === city) {
          cityId = item.id
        }
      });
      return cityId;
  }

  const getUfs = async () => {
    let arr = [];
    UFS.forEach(item => arr.push(<option key={item.sigla} value={item.sigla}>{`${item.sigla}`}</option>))
    setUfArr(arr);
    const ufId = fetchUfId(entity.address.estate);
    const mun = await getCities(ufId);
    setCities(mun);
  }

  useEffect(() => {
    getUfs();
  }, [entity.address.estate]);

  const setGeo = (index, val) => {
    let arr = entity.address.coordinates;
    arr[index] = val;
    setEntity({
      ...entity,
      address: { ...entity.address, coordinates: arr }
    });
  }
  return (
    <div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-5">
          <label htmlFor="inputName" className="">
            Endereço
          </label>
          <input
            value={(entity.address.street || "").toUpperCase() || ""}
            onChange={(e) =>
              setEntity({
                ...entity,
                address: { ...entity.address, street: e.target.value },
              })
            }
            type="text"
            id="inputStreet"
            className="form-control"
            placeholder="Endereço"
          />
        </div>
        <div className="form-group col-md-1">
          <label htmlFor="inputAddressNum" className="">
            Número
          </label>
          <input
            value={(entity.address.number || "").toUpperCase() || ""}
            onChange={(e) =>
              setEntity({
                ...entity,
                address: { ...entity.address, number: e.target.value },
              })
            }
            type="text"
            id="inputAddressNum"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="inputZip" className="">
            CEP
          </label>
          <InputMask
            value={entity.address.zip || ""}
            onBlur={(e) => { checkCep(e.target.value) }}
            onChange={(e) =>
              setEntity({
                ...entity,
                address: { ...entity.address, zip: e.target.value },
              })
            }
            maskChar={null}
            mask="99999-999"
            type="text"
            id="inputZip"
            className={cepVal.className}
            placeholder="CEP"
            required={cepVal.required}
          />
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-1">
          <label htmlFor="inputEstate" className="">
            UF
          </label>
          <select
            value={((entity.address || {}).estate || '')}
            onChange={(e) => {
              setEntity({ ...entity, address: { ...entity.address, estate: e.target.value } });
            }
            }
            id="inputEstate"
            className="custom-select"
          >
            <option value=""></option>
            {ufArr}
          </select>
        </div>

        <div className="form-group col-md-5">
          <label htmlFor="inputCity" className="">
            Municipio
          </label>
          <select
            value={((entity.address || {}).city || '')}
            onChange={
              (e) => setEntity({ ...entity, address: { ...entity.address, city: e.target.value, cityCode: fetchCityId(e.target.value) } })

            }
            type="text"
            id="inputCity"
            className="form-control"
            placeholder="Municipio"
          >
            <option value=""></option>
            {cities.map(item =>
              <option key={item.nome} value={(item.nome || "").toUpperCase()}>
                {`${(item.nome || "").toUpperCase()}`}</option>)}
          </select>
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="inputNeighbourhood" className="">
            Bairro
          </label>
          <input
            value={entity.address.neighbourhood}
            onChange={(e) => handleInputChange(e, "neighbourhood")}
            type="text"
            id="inputNeighbourhood"
            className="form-control"
            placeholder="Bairro"
          />
        </div>


      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-3">
          <label htmlFor="inputLatitude" className="">
            Latitude
          </label>
          <InputMask
            value={(entity.address.coordinates || {})[0] || ""}
            onChange={(e) =>
              setGeo(0, e.target.value)
            }
            mask="-99.999999"
            type="text"
            id="inputLatitude"
            className="form-control"
            placeholder="Latitude"
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputLongitude" className="">
            Longitude
          </label>
          <InputMask
            value={(entity.address.coordinates || {})[1] || ""}
            onChange={(e) =>
              setGeo(1, e.target.value)
            }
            mask="-99.999999"
            type="text"
            id="inputLongitude"
            className="form-control"
            placeholder="Longitude"
          />
        </div>
      </div>
    </div>
  );
};

import { axiosInstance } from './Interceptor';

export const getUsers = async (page = 1, search = '') => {
    try {
        const response = await axiosInstance.get(`users?page=${page}&search=${search}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const getUser = async (id) => {
    try {
        const response = await axiosInstance.get(`users/${id}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const deleteUser = async (id) => {
    try {
        const response = await axiosInstance.delete(`users/${id}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const editUser = async (id, data) => {
    try {
        const response = await axiosInstance.put(`users/${id}`, data);
        return response;
    } catch (err) {
        throw err;
    }
}

export const createUser = async (data) => {
    try {
        const response = await axiosInstance.post(`users`, data);
        return response;
    } catch (err) {
        throw err;
    }
}
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {createTruckdriver} from '../../../../helpers/TruckDriver';
import {cpf} from 'cpf-cnpj-validator';
import InputMask from 'react-input-mask';

export const CreateTruckDriver = () => {
  const [truckdriver, settruckdriver] = useState({});
  const [loading, setLoading] = useState(false);
  const [docVal, setDocVal] = useState('form-control');

  let history = useHistory();

  const checkDoc = (e) => {
    if (!cpf.isValid(e.target.value)) {
      setDocVal('form-control is-invalid');
      return;
    }
    setDocVal('form-control');
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!cpf.isValid(truckdriver.doc)) {
      return;
    }
    setLoading(true);
    try {
      await createTruckdriver(truckdriver);
      setLoading(false);
      setTimeout(() => history.push('/portal/motoristas'), 200);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="page-container">
      <h3>Criar Motorista</h3>
      <form
        onSubmit={async (evt) => !loading && (await handleSubmit(evt))}
        className="card-style">
        <label htmlFor="inputName" className="">
          Nome
        </label>
        <input
          value={truckdriver.name || ''}
          onChange={(e) =>
            settruckdriver({
              ...truckdriver,
              name: (e.target.value || '').toUpperCase(),
            })
          }
          type="text"
          id="inputName"
          className="form-control"
          placeholder="Nome"
          required={true}
          autoFocus=""
          maxLength="40"
        />

        <label htmlFor="inputDoc" className="mt-3">
          Documento
        </label>
        <InputMask
          value={truckdriver.doc || ''}
          onBlur={(e) => checkDoc(e)}
          onChange={(e) =>
            settruckdriver({...truckdriver, doc: e.target.value})
          }
          type="text"
          id="inputDoc"
          placeholder="CPF"
          required={true}
          autoFocus=""
          className={docVal}
          maskChar={null}
          mask="999.999.999-99"
        />

        <button
          disabled={loading}
          className="btn btn-lg btn-primary btn-block mt-4 col-sm-12 col-md-4"
          type="submit">
          {loading ? (
            <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
          ) : (
            'Criar'
          )}
        </button>
      </form>
    </div>
  );
};

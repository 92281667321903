import { axiosInstance } from './Interceptor';
import { createContext } from 'react';

export const login = async (email, password) => {
    try {
        const response = await axiosInstance.post('users/login', { email, password });
        return response;
    } catch (err) {
        throw err;
    }
}

export const logout = async () => {
    try {
        const response = await axiosInstance.post('users/me/logout', {});
        return response;
    } catch (err) {
        throw err;
    }
}

export const AuthContext = createContext();

export const initialAuthState = {
    isAuthenticated: localStorage.getItem('isAuthenticated') || false,
    email: localStorage.getItem('email') || null,
    level: localStorage.getItem('level') || null,
    token: localStorage.getItem('token') || null,
};

export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem("isAuthenticated", true);
            localStorage.setItem("email", action.payload.email);
            localStorage.setItem("level", action.payload.level);
            localStorage.setItem("token", action.payload.token);

            return {
                ...state,
                isAuthenticated: true,
                email: action.payload.email,
                level: action.payload.level,
                token: action.payload.token
            };
        case "LOGOUT":
            localStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                email: null,
                level: null,
                token: null
            };
        default:
            return state;
    }
};
import React, { useEffect, useState } from 'react';
import { getMilkRoutes, deleteMilkRoute, createMilkRoute, editMilkRoute } from '../../../helpers/MilkRoute';
import { getFarmersName } from '../../../helpers/Farmer';
import { Loading } from '../../dumb/Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faArrowUp, faArrowDown, faTimesCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationModal } from '../../dumb/ConfirmationModal/ConfirmationModal';

export const MilkRoutes = () => {
    const [entities, setEntities] = useState(null);
    const [farmersList, setFarmersList] = useState(null);
    const [newRoute, setNewRoute] = useState({});
    const [showRoute, setShowRoute] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [entityToDelete, setEntityToDelete] = useState({});
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [dropDownFarmers, setDropDownFarmers] = useState([]);
    const [farmerToAdd, setFarmerToAdd] = useState(null);

    useEffect(() => {
        (async () => {
            await handleGetMilkRoutes();
            await handleGetFarmersNames();
        })();
        return () => {};
    }, []);

    const moveItem = (route, code, delta) => {
        let farmers = route.farmers;
        let old_index = farmers.indexOf(code);
        let new_index = old_index + delta;
        if (new_index < 0 || new_index === farmers.length) {
            return
        }
        let arr = farmers;
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        let newEntities = [];
        entities.forEach((entity) => {
            if (entity.code === route.code) {
                entity.farmers = arr;
                newEntities.push(entity);
            } else {
                newEntities.push(entity);
            }
        });
        setEntities(newEntities);
        editMilkRoute(route._id, arr)
    }

    const removeItem = (route, code) => {
        let farmers = route.farmers;
        farmers.forEach((farmer) => {
            if (farmer === code) {
                farmers.splice(farmers.indexOf(farmer), 1);
            }
        })
        let newEntities = [];
        entities.forEach((entity) => {
            if (entity.code === route.code) {
                entity.farmers = farmers;
                newEntities.push(entity);
            } else {
                newEntities.push(entity);
            }
        });
        setEntities(newEntities);
        editMilkRoute(route._id, farmers)
    }

    const popDrop = (farmers) => {
        let newList = (farmersList || []).filter(farmer => !farmers.includes(farmer.id));
        let drop = [<option value="" selected>selecionar...</option>];
        newList.forEach((farmer) => drop.push(<option key={farmer.id} value={farmer.id}>{farmer.id}-{farmer.name}</option>));
        setDropDownFarmers(drop);
    }

    const addFarmer = (route, code) => {
        let routeCode = route.code;
        let farmers = route.farmers;
        if (!code) {
            return
        }
        code = parseInt(code);
        farmers.push(code)
        let newEntities = [];
        entities.forEach((entity) => {
            if (entity.code === routeCode) {
                entity.farmers = farmers;
                newEntities.push(entity);
            } else {
                newEntities.push(entity);
            }
        });
        setEntities(newEntities);
        editMilkRoute(route._id, farmers)
        setFarmerToAdd(null);
        popDrop(farmers);
    }

    const retFarmerName = (code) => {
        let name = '';
        (farmersList || []).forEach(farmer => {
            if (+farmer.id === code) {
                name = farmer.name;
            }
        });
        return name;
    }

    const dropRoute = (route) => {
        setShowRoute(showRoute[route] ? { [route]: false } : { [route]: true })
    }

    const createNewRoute = async () => {
        if (!newRoute.code) {
        }
        try {
            await createMilkRoute(newRoute);
            setLoadingAdd(false);
            handleGetMilkRoutes();
            //  setTimeout(() => history.push('/portal/rotas'), 200);  retirar?
        } catch (error) {
            setLoadingAdd(false);
        }
    }

    const handleGetMilkRoutes = async () => {
        try {
            const response = await getMilkRoutes();
            setEntities(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const handleGetFarmersNames = async () => {
        try {
            const response = await getFarmersName();
            setFarmersList(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const handleDelete = async (id) => {
        try {
            setLoadingDelete(true);
            await deleteMilkRoute(id);
            await handleGetMilkRoutes();
            setLoadingDelete(false);
        } catch (error) {
            setLoadingDelete(false);
        }
    }

    return (
        loading ?
            <Loading size="2x"></Loading>
            :
            <div className="page-container">
                <h3>Rotas</h3>
                <div className="truckdriver-header text-right mb-4">
                    <button className="btn btn-primary" onClick={() => setLoadingAdd(true)}>Adicionar Rota</button>
                </div>
                <div className="card-style">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Codigo</th>
                                <th scope="col">Produtores</th>
                                <th scope="col">Remover</th>
                            </tr>
                        </thead>

                        {
                            (entities || []).map((entity) => {
                                return (<tbody><tr className="clickable-row" key={entity._id}>
                                    <td onClick={() => dropRoute(entity.code)}>{entity.code}</td>
                                    <td onClick={() => dropRoute(entity.code)}>{(entity.farmers || []).map(farmer => `${farmer} ⇨ `)}</td>
                                    <td>
                                        {
                                            loadingDelete && entityToDelete._id === entity._id ?
                                                <Loading size="1x"></Loading>
                                                :
                                                <FontAwesomeIcon
                                                    className="delete-button"
                                                    icon={faTrash}
                                                    data-toggle="modal"
                                                    data-target="#confirmationModal"
                                                    onClick={() => setEntityToDelete(entity)}
                                                ></FontAwesomeIcon>}
                                    </td>
                                </tr>
                                    {
                                        showRoute[entity.code] ?
                                            <td colspan="3">

                                                {(entity.farmers || []).map(farmer =>
                                                    <tr className="clickable-row" key={farmer}> <td>{farmer}-{retFarmerName(farmer)}
                                                    </td>
                                                        <td><FontAwesomeIcon
                                                            icon={faArrowUp}
                                                            onClick={() => moveItem(entity, farmer, -1)}
                                                        ></FontAwesomeIcon></td>
                                                        <td><FontAwesomeIcon
                                                            icon={faArrowDown}
                                                            onClick={() => moveItem(entity, farmer, 1)}
                                                        ></FontAwesomeIcon></td>
                                                        <td><FontAwesomeIcon
                                                            icon={faTimesCircle}
                                                            onClick={() => removeItem(entity, farmer)}
                                                        ></FontAwesomeIcon></td>
                                                    </tr>)}<tr className="clickable-row"><td><select
                                                        className="custom-select"
                                                        onFocus={() => popDrop(entity.farmers)}
                                                        onChange={(e) => { setFarmerToAdd(e.target.value); popDrop(entity.farmers) }}
                                                    >
                                                        {dropDownFarmers}
                                                    </select></td>
                                                    <td colspan="3">
                                                        <button className="btn btn-primary"
                                                            onClick={e => addFarmer(entity, farmerToAdd)}><FontAwesomeIcon
                                                                icon={faPlus}
                                                            ></FontAwesomeIcon>
                                                        </button></td></tr>
                                            </td> :
                                            null}
                                </tbody>
                                )
                            })
                        }

                    </table>
                    {
                        loadingAdd ? <div className="form-group row">
                            <div className="col-md-3">
                                <input
                                    type="text" id="inputCode"
                                    className="form-control"
                                    placeholder="Nome"
                                    autoFocus={true}
                                    onChange={e => {
                                        setNewRoute({ ...newRoute, code: e.target.value });
                                    }}>
                                </input>
                            </div>
                            <div className="col-md-3">
                                <button className="btn btn-primary"
                                    onClick={() => createNewRoute()}><FontAwesomeIcon
                                        icon={faPlus}
                                    ></FontAwesomeIcon>
                                </button>
                            </div>
                        </div> : null}



                </div>
                <ConfirmationModal
                    title={`Deletar Rota ${entityToDelete && entityToDelete.name}?`}
                    message="Esta ação não pode ser revertida"
                    action={async () => await handleDelete(entityToDelete._id)}>
                </ConfirmationModal>
            </div>
    )
}
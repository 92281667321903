import React, { useReducer } from 'react';
import './Dashboard.css';
import { CustomHeader } from '../../dumb/Header/Header';
import { Sidebar } from '../../dumb/Sidebar/Sidebar';
import { Users } from '../../smart/Users/Users';
import { CreateUser } from '../Users/CreateUser/CreateUser';
import { EditUser } from '../Users/EditUser/EditUser';
import { TruckDriver } from '../../smart/TruckDriver/TruckDriver';
import { CreateTruckDriver } from '../TruckDriver/CreateTruckDriver/CreateTruckDriver';
import { EditTruckDriver } from '../TruckDriver/EditTruckDriver/EditTruckDriver';
import { Vehicles } from '../Vehicles/Vehicles';
import { CreateVehicle } from '../Vehicles/CreateVehicle/CreateVehicle';
import { Farmers } from '../../smart/Farmers/Farmers';
import { MilkRoutes } from '../../smart/MilkRoutes/MilkRoutes';
import { Trips } from '../../smart/Trips/Trips';
import { AddTrip } from '../Trips/AddTrip'
import { EditTrip } from '../Trips/EditTrip'
import { CreateFarmer } from '../../smart/Farmers/CreateFarmer/CreateFarmer';
import { EditVehicle } from '../Vehicles/EditVehicle/EditVehicle';
import { Config } from '../Config/Config';
import { Export } from '../Export/Export';

import {
    Router,
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

export const sidebarReducer = (state, action) => {
    switch (action.type) {
        case "TOGGLE":
            return {
                ...state,
                expanded: !state.expanded
            };
        default:
            return state;
    }
};

export const Dashboard = ({ email, level, dispatch, history }) => {
    const [state, sidebarDispatch] = useReducer(sidebarReducer, { expanded: true });
    let { path } = useRouteMatch();

    return <div className="dashboard">

        <Router history={history}>
            <CustomHeader email={email} dispatch={dispatch} sidebarDispatch={sidebarDispatch}></CustomHeader>
            <div className="content-container">
                <Sidebar level={level} expanded={state.expanded}></Sidebar>
                <div className="main-content">
                    <Switch>
                        <Route exact path={`${path}/usuarios/criar`}>
                            <CreateUser></CreateUser>
                        </Route>
                        <Route path={`${path}/usuarios/:id`}>
                            <EditUser></EditUser>
                        </Route>
                        <Route exact path={`${path}/usuarios`}>
                            <Users></Users>
                        </Route>
                        <Route exact path={`${path}/coletas`}>
                            <Trips></Trips>
                        </Route> 
                        <Route exact path={`${path}/coletas/criar`}>
                            <AddTrip></AddTrip>
                        </Route> 
                        <Route exact path={`${path}/coletas/editar/:id`}>
                            <EditTrip></EditTrip>
                        </Route> 
                        <Route exact path={`${path}/rotas`}>
                            <MilkRoutes></MilkRoutes>
                        </Route>
                        <Route exact path={`${path}/produtores/criar`}>
                            <CreateFarmer></CreateFarmer>
                        </Route>
                        <Route exact path={`${path}/produtores/:id`}>
                            <CreateFarmer></CreateFarmer>
                        </Route>
                        <Route exact path={`${path}/produtores`}>
                            <Farmers></Farmers>
                        </Route>
                        <Route exact path={`${path}/motoristas/criar`}>
                            <CreateTruckDriver></CreateTruckDriver>
                        </Route>
                        <Route path={`${path}/motoristas/:id`}>
                            <EditTruckDriver></EditTruckDriver>
                        </Route>
                        <Route path={`${path}/motoristas`}>
                            <TruckDriver></TruckDriver>
                        </Route>
                        <Route exact path={`${path}/vehiculos/criar`}>
                            <CreateVehicle></CreateVehicle>
                        </Route>
                        <Route path={`${path}/vehiculos/:id`}>
                            <EditVehicle></EditVehicle>
                        </Route>
                        <Route path={`${path}/vehiculos`}>
                            <Vehicles></Vehicles>
                        </Route>
                        <Route path={`${path}/configuracoes`}>
                            <Config></Config>
                        </Route>
                        <Route path={`${path}/exportar`}>
                            <Export></Export>
                        </Route>
                        <Route path={`${path}`}>
                            <div className="m-4">
                                <h4>Portal Sistema de Coleta</h4>
                            </div>
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    </div>
}

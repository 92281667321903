import React, {useState, useEffect} from 'react';
import {initialAuthState} from '../../../helpers/Auth';
import {useHistory, useParams} from 'react-router-dom';
import {Loading} from '../../dumb/Loading/Loading';
import {getTrip, editTrip} from '../../../helpers/Trip';
import {getAllTruckDrivers} from '../../../helpers/TruckDriver';
import {getAllVehicles} from '../../../helpers/Vehicle';
import {getFarmersName} from '../../../helpers/Farmer';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {getRoutesFromMilksRota} from '../../../helpers/MilkRoute';

export const EditTrip = () => {
  const [entity, setEntity] = useState({
    collect: [],
    vol: 0,
    driver: {},
    labOperator: initialAuthState.email,
    token: 0,
  });
  const [loading, setLoading] = useState(true);
  const [truckDrivers, setTruckDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [farmers, setFarmers] = useState([]);
  const [farmerToAdd, setFarmerToAdd] = useState('');
  const [showNewBox, setShowNewBox] = useState('none');
  const [showButton, setShowButton] = useState('');
  const [loadingMilksRota, setLoadingMilksRota] = useState(false);

  let history = useHistory();
  let {id} = useParams();

  const handleGetVehicles = async () => {
    try {
      const response = await getAllVehicles();
      setVehicles(response.entities);
    } catch (error) {}
  };

  const popVehicles = () =>
    vehicles.map((item) => (
      <option key={item._id} value={item.plate}>
        {item.plate}
      </option>
    ));

  const handleGetAllFarmers = async () => {
    try {
      const response = await getFarmersName();
      setFarmers(response);
    } catch (error) {}
  };

  const retFarmerName = (code) => {
    let name = '';
    farmers.forEach((farmer) => {
      if (+farmer.id === +code) {
        name = farmer.name;
      }
    });
    return name;
  };

  const handleGetAllTruckDrivers = async () => {
    try {
      const response = await getAllTruckDrivers();
      setTruckDrivers(response.entities);
    } catch (error) {}
  };

  const popTruckDrivers = () =>
    truckDrivers.map((item) => (
      <option key={item._id} value={item.doc}>
        {item.name}
      </option>
    ));

  const setTruckDriver = (e) => {
    const driver = truckDrivers.find((item) => item.doc === e);
    setEntity({...entity, driver: {name: driver.name, doc: driver.doc}});
  };

  const updateVol = (farmerId, vol) => {
    const collect = entity.collect.map((item) => {
      if (item.farmerId === farmerId) {
        item.vol = +vol || 0;
      } else {
        item.vol = +item.vol || 0;
      }
      return item;
    });
    let tot = collect.reduce((prev, curr) => prev + curr.vol, 0);
    setEntity((entity) => ({...entity, collect: collect, vol: tot}));
  };

  const createTable = () => {
    return (entity.collect || []).map((item) => (
      <tr key={item.farmerId}>
        <td>{item.farmerId}</td>
        <td>{retFarmerName(item.farmerId)}</td>
        <td>
          <input
            type="text"
            className="form-control"
            style={{height: '27px', width: '100px'}}
            value={item.vol || ''}
            maxLength="5"
            onKeyPress={(e) => /[^0-9]$/.test(e.key) && e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            onChange={(e) =>
              updateVol(item.farmerId, +e.target.value || 0)
            }></input>
        </td>
      </tr>
    ));
  };

  const handleGetTrip = async () => {
    try {
      const tripResponse = await getTrip(id);
      setEntity(tripResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const syncMilksRota = async (token) => {
    try {
      setLoadingMilksRota(true);
      const response = await getRoutesFromMilksRota(token, 1);

      const driver = truckDrivers.find(
        (d) => d.name.toUpperCase() === response.driver.name.toUpperCase(),
      );

      setEntity({
        ...entity,
        collect: response.collect.map((item) => ({
          farmerId: item.farmerId,
          vol: +item.vol,
          id: item.id,
          disableVolInput: !!item.vol,
        })),
        routeCode: response.routeCode,
        vehiclePlate: response.vehiclePlate,
        driver,
        vol: response.collect.reduce((acc, item) => acc + +item.vol, 0),
        token,
      });
      setLoadingMilksRota(false);
    } catch (error) {
      setLoadingMilksRota(false);
    }
  };

  useEffect(() => {
    (async () => {
      await handleGetTrip();
      await handleGetAllFarmers();
      await handleGetAllTruckDrivers();
      await handleGetVehicles();
      setLoading(false);
    })();
    return () => {};
  }, []);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    if (+entity.vol === 0) {
      setLoading(false);
      return;
    }
    // provisional until fix vol sum problems
    let tot = entity.collect.reduce((prev, curr) => prev + curr.vol, 0);
    setEntity((entity) => ({...entity, vol: tot}));
    // ****

    let arr = entity.collect.filter((item) => +item.vol !== 0);
    entity.collect = arr;

    try {
      await editTrip(id, entity);
      setLoading(false);
      setTimeout(() => history.push('/portal/coletas'), 200);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="page-container">
      {loading ? (
        <Loading size="2x"></Loading>
      ) : (
        <form
          onSubmit={async (evt) => !loading && (await handleSubmit(evt))}
          className="card-style">
          <div className="form-row justify-content-md-center">
            <div className="form-group col-md-1">
              <input
                disabled={true}
                value={entity.routeCode || ''}
                required={true}
                className="custom-select"
                id="validationRoute"></input>
              <div className="invalid-feedback">Selecione uma Rota.</div>
            </div>
            <div className="form-group col-md-4">
              <select
                value={(entity.driver || {}).doc || ''}
                onChange={(e) => setTruckDriver(e.target.value)}
                required={true}
                className="custom-select"
                id="validationDriver">
                <option value="" disabled>
                  Motorista...
                </option>
                {popTruckDrivers()}
              </select>
              <div className="invalid-feedback">Selecione um Motorista.</div>
            </div>
            <div className="form-group col-md-2">
              <select
                value={entity.vehiclePlate || ''}
                onChange={(e) =>
                  setEntity({...entity, vehiclePlate: e.target.value})
                }
                required={true}
                className="custom-select"
                id="validationVehicle">
                <option value="" disabled>
                  Veiculo...
                </option>
                {popVehicles()}
              </select>
              <div className="invalid-feedback">Selecione um Veículo.</div>
            </div>
            <div className="form-group col-md-2">
              <input
                type="text"
                className="form-control"
                placeholder="Token"
                value={entity.token || ''}
                onChange={(e) =>
                  setEntity({
                    ...entity,
                    token: e.target.value.replace(/[^0-9]/g, ''),
                  })
                }
              />
            </div>
            <div className="form-group col-md-2">
              <button
                type="button"
                disabled={
                  loadingMilksRota || `${entity?.token || ''}`.length < 7
                }
                className="btn btn-primary btn-block"
                onClick={() => {
                  `${entity?.token || ''}`.length >= 7 &&
                    syncMilksRota(entity.token);
                }}>
                Buscar Coleta
              </button>
            </div>
          </div>
          {loadingMilksRota && (
            <div className="mb-4">
              <Loading size="1x"></Loading>
            </div>
          )}
          <div className="form-row justify-content-md-center">
            <table className="table table-striped table-sm">
              <thead className="thead-dark">
                <tr>
                  <th>Código</th>
                  <th>Produtor</th>
                  <th>Volume</th>
                </tr>
              </thead>
              <tbody>{createTable()}</tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3" style={{display: showNewBox}}>
              <select
                onChange={(e) => {
                  setFarmerToAdd(e.target.value);
                  if (!farmerToAdd) {
                    setShowButton('block');
                  }
                }}
                className="custom-select"
                id="newFarmer"
                value={farmerToAdd || ''}>
                <option value="" disabled defaultValue>
                  Selecione...
                </option>
                {farmers.map((farmer) => (
                  <option key={farmer.id} value={farmer.id}>
                    {farmer.id}-{farmer.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2" style={{display: showButton}}>
              <span>
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  onClick={(e) => {
                    if (showNewBox !== 'none') {
                      let newColl = entity.collect;
                      newColl.push({farmerId: farmerToAdd, vol: 0});
                      setEntity({...entity, collect: newColl});
                      setShowNewBox('none');
                      setFarmerToAdd('');
                    } else {
                      setShowNewBox('block');
                      setShowButton('none');
                    }
                  }}></FontAwesomeIcon>
              </span>
            </div>
          </div>
          <div className="card-footer mt-1">
            <div className="row justify-content-md-center">
              <div className="col-md-auto">
                <span>Medidos: </span>
                <span>{(entity.tot || 0).toLocaleString()}</span>
              </div>
              <div className="col-md-auto">
                <span>Diferença: </span>
                <span
                  style={{
                    color: entity.tot - entity.vol < 0 ? 'red' : 'black',
                  }}>
                  {(entity.tot - entity.vol).toLocaleString()}
                </span>
              </div>
              <div className="col-md-auto">
                <span>Total: </span>
                <span>{(entity.vol || 0).toLocaleString()}</span>
              </div>
            </div>
          </div>
          <div className="form-row justify-content-md-center">
            <div className="form-group col-md-4 mt-4">
              <button
                disabled={loading}
                className="btn btn-lg btn-primary btn-block"
                type="submit">
                {loading ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
                ) : (
                  'Salvar'
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getFarmers } from '../../../helpers/Farmer';
import { getMilkRoutes } from '../../../helpers/MilkRoute';
import { Loading } from '../../dumb/Loading/Loading';
import { SearchBar } from '../../dumb/SearchBar/SearchBar';
import { PaginationBar } from '../../dumb/PaginationBar/PaginationBar';

export const Farmers = () => {
  // pagination config
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [tempSearch, setTempSearch] = useState('');
  const [totalItems, setTotalItems] = useState(null);
  const [milkRoutes, setMilkRoutes] = useState({});
  const [search, setSearch] = useState('');
  useEffect(() => {
    (async () => {
      if (page) {
        await handleGetMilkRoutes();
        await handleGetFarmers(page, search);
      }
    })();
  }, [page, search]);

  const [entities, setEntities] = useState(null);
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  let { path } = useRouteMatch();

  const retRoute = (farmerCode) => {
    let route = [];
    milkRoutes.forEach(item => {
      if (item.farmers.find(code => code === farmerCode)) {
        route.push(item.code);
      }
    });
    if (route.length > 0) {
      return route.toString();
    } else {
      return '-'
    }
  }

  const handleGetMilkRoutes = async () => {
    try {
      const milkRoutes = await getMilkRoutes();
      setMilkRoutes(milkRoutes);
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetFarmers = async () => {
    try {
      setLoading(true);
      const { entities, totalItems, itemsPerPage } = await getFarmers(
        page,
        search,
      );
      setEntities(entities);
      setTotalItems(totalItems);
      setItemsPerPage(itemsPerPage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = (evt) => {
    evt.preventDefault();
    setPage(1);
    setSearch(tempSearch);
  };

  return (
    <div className="page-container">
      <h3>Produtores</h3>
      <div className="users-header text-right mb-4">
        <button
          className="btn btn-primary"
          onClick={() => history.push(`${path}/criar`)}>
          Adicionar Produtor
        </button>
      </div>
      <div className="card-style">
        {loading ? (
          <Loading size="2x"></Loading>
        ) : (
            <div>
              <SearchBar
                tempSearch={tempSearch}
                handleSearch={handleSearch}
                setSearch={(evt) => setTempSearch(evt.target.value)}></SearchBar>
              <table className="table table-striped table-bordered mt-4">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Nome</th>
                    <th scope="col">CPF</th>
                    <th scope="col">IE</th>
                    <th scope="col">Rota</th>
                  </tr>
                </thead>
                <tbody>
                  {(entities || []).map((entity) => (
                    <tr className="clickable-row" key={entity._id}>
                      <td onClick={() => history.push(`${path}/${entity._id}`)}>
                        {entity.id}
                      </td>
                      <td onClick={() => history.push(`${path}/${entity._id}`)}>
                        {entity.name}
                      </td>
                      <td onClick={() => history.push(`${path}/${entity._id}`)}>
                        {entity.brlCNPJCPF}
                      </td>
                      <td onClick={() => history.push(`${path}/${entity._id}`)}>
                        {entity.brlIE}
                      </td>
                      <td onClick={() => history.push(`${path}/${entity._id}`)}>
                        {retRoute(entity.id)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <PaginationBar
                page={page}
                setPage={setPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}></PaginationBar>
            </div>
          )}
      </div>
    </div>
  );
};

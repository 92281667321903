import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {getTrips} from '../../../helpers/Trip';
import {Loading} from '../../dumb/Loading/Loading';
import {SearchBar} from '../../dumb/SearchBar/SearchBar';
import {PaginationBar} from '../../dumb/PaginationBar/PaginationBar';

export const Trips = () => {
  // pagination config
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [tempSearch, setTempSearch] = useState('');
  const [totalItems, setTotalItems] = useState(null);
  const [search, setSearch] = useState('');
  useEffect(() => {
    (async () => {
      if (page) {
        await handleGetTrips(page, search);
      }
    })();
  }, [page, search]);

  const [entities, setEntities] = useState(null);
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  let {path} = useRouteMatch();

  const handleGetTrips = async () => {
    try {
      setLoading(true);
      const {entities, totalItems, itemsPerPage} = await getTrips(
        page,
        search,
      );
      setEntities(entities);
      setTotalItems(totalItems);
      setItemsPerPage(itemsPerPage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = (evt) => {
    evt.preventDefault();
    setPage(1);
    setSearch(tempSearch);
  };

  return (
    <div className="page-container">
      <h3>Coletas</h3>
      <div className="users-header text-right mb-4">
        <button
          className="btn btn-primary"
          onClick={() => history.push(`${path}/criar`)}>
          Registrar Coleta
        </button>
      </div>
      <div className="card-style">
        {loading ? (
          <Loading size="2x"></Loading>
        ) : (
          <div>
            <SearchBar
              tempSearch={tempSearch}
              handleSearch={handleSearch}
              setSearch={(evt) => setTempSearch(evt.target.value)}></SearchBar>
            <table className="table table-striped table-bordered mt-4">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Código</th>
                  <th scope="col">Rota</th>
                  <th scope="col">Data</th>
                  <th scope="col">Motorista</th>
                  <th scope="col">Veiculo</th>
                  <th scope="col">Total</th>
                  <th scope="col">Medidos</th>
                  <th scope="col">Diferença</th>
                </tr>
              </thead>
              <tbody>
                {(entities || []).map((entity) => (
                  <tr className="clickable-row" key={entity._id}>
                    <td onClick={() => history.push(`${path}/editar/${entity._id}`)}>
                      {entity.id}
                    </td>
                    <td onClick={() => history.push(`${path}/editar/${entity._id}`)}>
                      {entity.routeCode}
                    </td>
                    <td onClick={() => history.push(`${path}/editar/${entity._id}`)}>
                      {new Date(entity.collectDate || "").toLocaleString('pt-BR', 
                      { timeZone: 'America/Sao_Paulo', year: 'numeric', month: 'numeric',
                       day: 'numeric', hour: 'numeric', minute: 'numeric'  })}
                    </td>
                    <td onClick={() => history.push(`${path}/editar/${entity._id}`)}>
                      {(entity.driver || {}).name}
                    </td>
                    <td onClick={() => history.push(`${path}/editar/${entity._id}`)}>
                      {entity.vehiclePlate}
                    </td>
                    <td onClick={() => history.push(`${path}/editar/${entity._id}`)}>
                      {(entity.vol || 0).toLocaleString()}
                    </td>
                    <td onClick={() => history.push(`${path}/editar/${entity._id}`)}>
                      {(entity.tot || 0).toLocaleString()}
                    </td>
                    <td onClick={() => history.push(`${path}/editar/${entity._id}`)}>
                    <span style={{ color: entity.tot - entity.vol < 0 ? "red" : "orange" }}>{(entity.tot - entity.vol).toLocaleString()}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationBar
              page={page}
              setPage={setPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}></PaginationBar>
          </div>
        )}
      </div>
    </div>
  );
};

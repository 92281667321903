import {axiosInstance} from './Interceptor';

export const getMilkRoutes = async () => {
  try {
    const response = await axiosInstance.get('milkRoute');
    return response;
  } catch (err) {
    throw err;
  }
};

export const getRoutesFromMilksRota = async (token, isEdit = 0) => {
  try {
    const response = await axiosInstance.get(
      `milkRoute/milksrota/${token}?isEdit=${isEdit}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getMilkRoute = async (id) => {
  try {
    const response = await axiosInstance.get(`milkRoute/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteMilkRoute = async (id) => {
  try {
    const response = await axiosInstance.delete(`milkRoute/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const editMilkRoute = async (id, data) => {
  try {
    const response = await axiosInstance.put(`milkRoute/${id}`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const createMilkRoute = async (data) => {
  try {
    const response = await axiosInstance.post(`milkRoute`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

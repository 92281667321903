import { axiosInstance } from './Interceptor';

export const getConfig = async () => {
    try {
        const response = await axiosInstance.get(`config`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const updateConfig = async (data) => {
    try {
        const response = await axiosInstance.put(`config`, data);
        return response;
    } catch (err) {
        throw err;
    }
}

export const createConfig = async (data) => {
    try {
        const response = await axiosInstance.post(`config`, data);
        return response;
    } catch (err) {
        throw err;
    }
}

import React from 'react';

export const PaginationBar = ({page, totalItems, itemsPerPage, setPage}) => {

    return <div className="pagination-custom">
        <ul className="pagination pagination-buttons">
            {
                page > 1 ?
                <li className="page-item" onClick={() => setPage(1)}><span className="page-link">Primeira</span></li>
                :
                <li className="page-item disabled"><span className="page-link">Primeira</span></li>
            }

            {
                page > 2 ?
                <li className="page-item" onClick={() => setPage(page - 2)}><span className="page-link">{page - 2}</span></li>
                :
                null
            }

            {
                page > 1 ?
                <li className="page-item" onClick={() => setPage(page - 1)}><span className="page-link">{page - 1}</span></li>
                :
                null
            }
            <li className="page-item active"><span className="page-link">{page}</span></li>
            
            {
                page * itemsPerPage < totalItems ?
                <li className="page-item" onClick={() => setPage(page + 1)}><span className="page-link">{page + 1}</span></li>
                :
                null
            }

            {
                (page + 1) * itemsPerPage < totalItems ?
                <li className="page-item" onClick={() => setPage(page + 2)}><span className="page-link">{page + 2}</span></li>
                :
                null
            }

            {
                page === Math.floor((totalItems / itemsPerPage) + 1)
                ?
                <li className="page-item disabled" onClick={() => setPage(Math.floor((totalItems / itemsPerPage) + 1))}><span className="page-link">Última</span></li>
                :
                <li className="page-item" onClick={() => setPage(Math.floor((totalItems / itemsPerPage) + 1))}><span className="page-link">Última</span></li>
            }
        </ul>
        <span>Total: {totalItems} </span>
    </div>
}
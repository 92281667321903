import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFarmer, getFarmer, editFarmer } from "../../../../helpers/Farmer";
import { FarmerMain } from "../../../dumb/Farmers/FarmerMain"
import { FarmerAddress } from "../../../dumb/Farmers/FarmerAddress"
import { FarmerAccount } from "../../../dumb/Farmers/FarmerAccount"
import { ConfirmationModal } from "../../../dumb/ConfirmationModal/ConfirmationModal";
import './CreateFarmer.css'

export const CreateFarmer = () => {
  const [validation, setValidation] = useState([]);
  const [erro, setErro] = useState({});
  const [entity, setEntity] = useState({
    address: {
      coordinates: ["", ""]
    },
    bankAcc: {
      accountName: null
    },
  });

  const $ = window.$

  const [loading, setLoading] = useState(false);
  let history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    const handleGetFarmer = async () => {
      console.log(`farmer id ${id}`);
      if (!id) {
        return
      }
      try {
        const response = await getFarmer(id);
        setEntity(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    (async () => {
      await handleGetFarmer();
    })();

    return () => { };
  }, [id]);


  const upValidation = (ob) => {
    let arr = validation;
    for(var i in validation){
      if(validation[i].id === ob.id){
        arr[i] = ob;
        setValidation(arr);
        return;
      }
    }
    arr.push(ob)
    setValidation(arr);
  }

  const handleSubmit = async (evt) => {
    
    let entityObj = {...entity};
    if (!entity.bankAcc.accountName) {
      entityObj = { ...entity, 
        bankAcc: { ...entity.bankAcc, accountName: entity.name, accountDoc: entity.doc } };
      setEntity(entityObj);
    }

    setErro({});
    let isValid = true;
    validation.forEach(item => {    
      if (!item.status) {
        setErro(item);
        isValid = false;
      }
    });
    if (!isValid) {
      $("#confirmationModal").modal("show")
      return
    }

    setLoading(true);
    try {
      if (!id) {
        await createFarmer(entityObj);
        setLoading(false);
        setTimeout(() => history.push("/portal/produtores"), 200);
      } else {
        await editFarmer(id, entityObj);
        setLoading(false);
      }

    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="page-container">
      <h3>Produtor</h3>
      <form
        onSubmit={async (evt) => !loading && (await handleSubmit(evt))}
        className="card-style"
      >
        <FarmerMain upValidation={upValidation}  entity={entity} setEntity={setEntity}></FarmerMain>
        <FarmerAddress upValidation={upValidation}  entity={entity} setEntity={setEntity}></FarmerAddress>
        <FarmerAccount upValidation={upValidation}  entity={entity} setEntity={setEntity}></FarmerAccount>
        <div className="form-row justify-content-md-center">
          <div className="form-group col-md-4 mt-4">
            <button
              disabled={loading}
              className="btn btn-lg btn-primary btn-block"
              type="submit"
            >
              {loading ? (
                <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
              ) : (
                  "Salvar"
                )}
            </button>
          </div>
        </div>
      </form>
      <ConfirmationModal
        title={`Dados ${erro.id} inválidos`}
        message="Verificar dados"
      ></ConfirmationModal>
    </div>
  );
};

import { toast } from 'react-toastify';
import { css } from 'glamor';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
})

const cssConfig = {
    fontSize: '18px',
    paddingLeft: '10px'
}

export const messageToast = (message, type) => {
    switch (type) {
        case 'success':
            toast.success(message, {
                bodyClassName: css(cssConfig)
            });
            break;
        case 'error':
            toast.error(message, {
                bodyClassName: css(cssConfig)
            });
            break;
        case 'info':
            toast.info(message, {
                bodyClassName: css(cssConfig)
            });
            break;
        default:
            toast.info(message, {
                bodyClassName: css(cssConfig)
            });
    }
}
import React from 'react';
import { Nav } from 'react-bootstrap';
import './Sidebar.css';
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faTruck, faUserFriends, faHatCowboy, faRoute, faClipboard, faFileExport, faWrench } from '@fortawesome/free-solid-svg-icons';

export const Sidebar = ({ level, expanded }) => {
    let { pathname } = useLocation();
    console.log(pathname);

    return <Nav className={expanded ? 'sidebar-custom' : 'sidebar-custom collapsed'}>
        <ul className="nav flex-column sidebar-ul">
            {
                <li className={pathname && pathname.includes('coletas') ? 'sidebar-li active-sidebar' : 'sidebar-li'}>
                    <Link className="sidebar-item sidebar-item-custom" to="/portal/coletas">
                        <span className="sidebar-item-icon">
                            <FontAwesomeIcon icon={faClipboard}></FontAwesomeIcon>
                        </span>
                        {expanded ? <span className="sidebar-item-title">
                            Coletas
                            </span> : null}
                    </Link>
                </li>
            }
            {
                level !== 'user' ?
                    <li className={pathname && pathname.includes('rotas') ? 'sidebar-li active-sidebar' : 'sidebar-li'}>
                        <Link className="sidebar-item sidebar-item-custom" to="/portal/rotas">
                            <span className="sidebar-item-icon">
                                <FontAwesomeIcon icon={faRoute}></FontAwesomeIcon>
                            </span>
                            {expanded ? <span className="sidebar-item-title">
                                Rotas
                            </span> : null}
                        </Link>
                    </li>
                    :
                    null
            }
            {
                level !== 'user' ?
                    <li className={pathname && pathname.includes('produtores') ? 'sidebar-li active-sidebar' : 'sidebar-li'}>
                        <Link className="sidebar-item sidebar-item-custom" to="/portal/produtores">
                            <span className="sidebar-item-icon">
                                <FontAwesomeIcon icon={faHatCowboy}></FontAwesomeIcon>
                            </span>
                            {expanded ? <span className="sidebar-item-title">
                                Produtores
                            </span> : null}
                        </Link>
                    </li>
                    :
                    null
            }
            {
                level !== 'user' ?
                    <li className={pathname && pathname.includes('motoristas') ? 'sidebar-li active-sidebar' : 'sidebar-li'}>
                        <Link className="sidebar-item sidebar-item-custom" to="/portal/motoristas">
                            <span className="sidebar-item-icon">
                                <FontAwesomeIcon icon={faUserFriends}></FontAwesomeIcon>
                            </span>
                            {expanded ? <span className="sidebar-item-title">
                                Motoristas
                            </span> : null}
                        </Link>
                    </li>
                    :
                    null
            }
            {
                level !== 'user' ?
                    <li className={pathname && pathname.includes('vehiculos') ? 'sidebar-li active-sidebar' : 'sidebar-li'}>
                        <Link className="sidebar-item sidebar-item-custom" to="/portal/vehiculos">
                            <span className="sidebar-item-icon">
                                <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
                            </span>
                            {expanded ? <span className="sidebar-item-title">
                                Veículos
                    </span> : null}
                        </Link>
                    </li>
                    :
                    null
            }
            {
                level === 'admin' ?
                    <li className={pathname && pathname.includes('usuarios') ? 'sidebar-li active-sidebar' : 'sidebar-li'}>
                        <Link className="sidebar-item sidebar-item-custom" to="/portal/usuarios">
                            <span className="sidebar-item-icon">
                                <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>
                            </span>
                            {expanded ? <span className="sidebar-item-title">
                                Usuários
                            </span> : null}
                        </Link>
                    </li>
                    :
                    null
            }
            {
                level === 'admin' ?
                    <li className={pathname && pathname.includes('configuracoes') ? 'sidebar-li active-sidebar' : 'sidebar-li'}>
                        <Link className="sidebar-item sidebar-item-custom" to="/portal/configuracoes">
                            <span className="sidebar-item-icon">
                                <FontAwesomeIcon icon={faWrench}></FontAwesomeIcon>
                            </span>
                            {expanded ? <span className="sidebar-item-title">
                                Configurações
                            </span> : null}
                        </Link>
                    </li>
                    :
                    null
            }
            {
                level === 'admin' ?
                    <li className={pathname && pathname.includes('exportar') ? 'sidebar-li active-sidebar' : 'sidebar-li'}>
                        <Link className="sidebar-item sidebar-item-custom" to="/portal/exportar">
                            <span className="sidebar-item-icon">
                                <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                            </span>
                            {expanded ? <span className="sidebar-item-title">
                                Exportar
                            </span> : null}
                        </Link>
                    </li>
                    :
                    null
            }
        </ul>
    </Nav>
}

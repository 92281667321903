import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createVehicle } from '../../../../helpers/Vehicle';
import './CreateVehicle.css';
import { ConfirmationModal } from '../../../dumb/ConfirmationModal/ConfirmationModal';

export const CreateVehicle = () => {
  const $ = window.$;
  const [entity, setEntity] = useState({
    bulk0: '',
    bulk1: '',
    bulk2: '',
  });
  const [loading, setLoading] = useState(false);
  const [docVal, setDocVal] = useState('form-control');
  let history = useHistory();

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (docVal.includes('invalid')) {
      $('#confirmationModal').modal('show');
      return;
    }
    setLoading(true);
    try {
      await createVehicle(entity);
      setLoading(false);
      setTimeout(() => history.push('/portal/vehiculos'), 200);
    } catch (error) {
      setLoading(false);
    }
  };

  const checkDoc = (e) => {
    if (e.target.value.trim().length < 8) {
      setDocVal('form-control is-invalid');
    } else {
      setDocVal('form-control');
    }
  };

  return (
    <div className="vehicles">
      <h3>Adicionar Veículo</h3>
      <form
        onSubmit={async (evt) => !loading && (await handleSubmit(evt))}
        className="card-style">
        <label htmlFor="inputType" className="">
          Tipo
        </label>
        <select
          value={(entity.type || '').toUpperCase()}
          onChange={(e) => setEntity({ ...entity, type: e.target.value })}
          id="inputType"
          className="form-control"
          placeholder="Tipo"
          required={true}
          autoFocus=""
        >
          <option value='' disabled>...</option>
          <option>TOCO</option>
          <option>TRUCK</option>
        </select>

        <label htmlFor="inputModel" className="mt-3">
          Modelo
        </label>
        <input
          value={(entity.model || '').toUpperCase()}
          onChange={(e) => setEntity({ ...entity, model: e.target.value })}
          type="text"
          id="inputModel"
          className="form-control"
          placeholder="Modelo"
          required={true}
          autoFocus=""
          maxLength="10"
        />

        <label htmlFor="inputPlate" className="mt-3">
          Placa
        </label>
        <InputMask
          value={(entity.plate || '').toUpperCase()}
          onBlur={(e) => checkDoc(e)}
          onChange={(e) => setEntity({ ...entity, plate: e.target.value })}
          className={docVal}
          maskChar={null}
          mask="aaa-9*99"
          type="text"
          id="inputPlate"
          placeholder="Placa"
          required={true}
          autoFocus=""
        />
        <div className="invalid-feedback">Placa Inválida</div>

        <label htmlFor="inputBulk0" className="mt-3">
          Tanque 1
        </label>
        <input
          value={entity.bulk0}
          onChange={(e) => setEntity({ ...entity, bulk0: +e.target.value })}
          type="number"
          id="inputBulk0"
          className="form-control"
          placeholder="Capacidade em Litros"
          required={true}
          autoFocus=""
          maxLength="5"
          onKeyPress={e => /[^0-9]$/.test(e.key) && e.preventDefault()}
          onPaste={e => e.preventDefault()}
        />

        <label htmlFor="inputBulk1" className="mt-3">
          Tanque 2
        </label>
        <input
          value={entity.bulk1}
          onChange={(e) => setEntity({ ...entity, bulk1: +e.target.value })}
          type="text"
          id="inputBulk1"
          className="form-control"
          placeholder="Capacidade em Litros"
          required={false}
          autoFocus=""
          maxLength="5"
          onKeyPress={e => /[^0-9]$/.test(e.key) && e.preventDefault()}
          onPaste={e => e.preventDefault()}
        />

        <label htmlFor="inputBulk2" className="mt-3">
          Tanque 3
        </label>
        <input
          value={entity.bulk2}
          onChange={(e) => setEntity({ ...entity, bulk2: +e.target.value })}
          type="text"
          id="inputBulk2"
          className="form-control"
          placeholder="Capacidade em Litros"
          required={false}
          autoFocus=""
          maxLength="5"
          onKeyPress={e => /[^0-9]$/.test(e.key) && e.preventDefault()}
          onPaste={e => e.preventDefault()}
        />

        <label htmlFor="inputBulkCap" className="mt-3">
          Total
        </label>
        <input
          value={entity.bulk0 + entity.bulk1 + entity.bulk2}
          type="text"
          id="inputBulkCap"
          className="form-control"
          placeholder="Capacidade Total do Tanque"
          disabled={true}
          autoFocus=""
        />

        <button
          disabled={loading}
          className="btn btn-lg btn-primary btn-block mt-4 col-sm-12 col-md-4"
          type="submit">
          {loading ? (
            <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
          ) : (
              'Criar'
            )}
        </button>
      </form>
      <ConfirmationModal
        title={`Dados da placa incompletos`}
        message="Verificar dados"></ConfirmationModal>
    </div>
  );
};

import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getTruckdriver, editTruckdriver} from '../../../../helpers/TruckDriver';
import {Loading} from '../../../dumb/Loading/Loading';
import './EditTruckDriver.css';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cpf} from 'cpf-cnpj-validator';
import InputMask from 'react-input-mask';

export const EditTruckDriver = () => {
  const [truckdriver, settruckdriver] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [docVal, setDocVal] = useState('form-control');

  let {id} = useParams();

  const checkDoc = (e) => {
    if (!cpf.isValid(e.target.value)) {
      setDocVal('form-control is-invalid');
      return;
    }
    setDocVal('form-control');
  };

  useEffect(() => {
    const handleGetTruckdriver = async () => {
      try {
        const truckdriversResponse = await getTruckdriver(id);
        settruckdriver(truckdriversResponse);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    (async () => {
      await handleGetTruckdriver();
    })();

    return () => {};
  }, [id]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!cpf.isValid(truckdriver.doc)) {
      return;
    }
    setLoadingSave(true);
    try {
      await editTruckdriver(id, truckdriver);
      setLoadingSave(false);
    } catch (error) {
      setLoadingSave(false);
    }
  };

  return loading ? (
    <Loading size="2x"></Loading>
  ) : truckdriver ? (
    <div className="page-container">
      <h3>Editar Motorista</h3>
      <form
        onSubmit={async (evt) => !loadingSave && (await handleSubmit(evt))}
        className="card-style">
        <label htmlFor="inputName" className="">
          Nome
        </label>
        <input
          value={truckdriver.name || ''}
          onChange={(e) =>
            settruckdriver({
              ...truckdriver,
              name: (e.target.value || '').toUpperCase(),
            })
          }
          type="text"
          id="inputName"
          className="form-control"
          placeholder="Nome"
          required={true}
          autoFocus=""
          maxLength="40"
        />

        <label htmlFor="inputDoc" className="mt-3">
          Documento
        </label>
        <InputMask
          value={truckdriver.doc || ''}
          onBlur={(e) => checkDoc(e)}
          onChange={(e) =>
            settruckdriver({...truckdriver, doc: e.target.value})
          }
          type="text"
          id="inputDoc"
          placeholder="CPF"
          required={true}
          autoFocus=""
          className={docVal}
          maskChar={null}
          mask="999.999.999-99"
        />

        <button
          disabled={loadingSave}
          className="btn btn-lg btn-primary btn-block mt-4 col-sm-12 col-md-4"
          type="submit">
          {loadingSave ? (
            <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
          ) : (
            'Salvar'
          )}
        </button>
      </form>
    </div>
  ) : (
    <h4>Motorista não encontrado</h4>
  );
};

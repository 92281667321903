import { axiosInstance } from './Interceptor';

export const getVehicles = async (page = 1, search = '') => {
    try {
        const response = await axiosInstance.get(`vehicles?page=${page}&search=${search}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const getAllVehicles = async (page = 1, search = '') => {
    try {
        const response = await axiosInstance.get(`vehicles?onlyNames=true`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const getVehicle = async (id) => {
    try {
        const response = await axiosInstance.get(`vehicles/${id}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const deleteVehicle = async (id) => {
    try {
        const response = await axiosInstance.delete(`vehicles/${id}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const editVehicle = async (id, data) => {
    try {
        const response = await axiosInstance.put(`vehicles/${id}`, data);
        return response;
    } catch (err) {
        throw err;
    }
}

export const createVehicle = async (data) => {
    try {
        const response = await axiosInstance.post(`vehicles`, data);
        return response;
    } catch (err) {
        throw err;
    }
}
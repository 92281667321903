import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import InputMask from 'react-input-mask';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {editVehicle, getVehicle} from '../../../../helpers/Vehicle';
import './EditVehicle.css';
import {ConfirmationModal} from '../../../dumb/ConfirmationModal/ConfirmationModal';
import {Loading} from '../../../dumb/Loading/Loading';

export const EditVehicle = () => {
  const $ = window.$;
  const [entity, setEntity] = useState({
    bulk0: '',
    bulk1: '',
    bulk2: '',
  });
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [docVal, setDocVal] = useState('form-control');

  let {id} = useParams();

  useEffect(() => {
    const handleGetVehicle = async () => {
      try {
        const response = await getVehicle(id);

        // initialize the bulks if they are empty
        response.bulk0 = response.bulk0 || '';
        response.bulk1 = response.bulk1 || '';
        response.bulk2 = response.bulk2 || '';
        response.bulk3 = response.bulk3 || '';

        setEntity(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    (async () => {
      await handleGetVehicle();
    })();

    return () => {};
  }, [id]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (docVal.includes('invalid')) {
      $('#confirmationModal').modal('show');
      return;
    }
    setLoadingSave(true);
    try {
      await editVehicle(id, entity);
      setLoadingSave(false);
    } catch (error) {
      setLoadingSave(false);
    }
  };

  const checkDoc = (e) => {
    if (e.target.value.trim().length < 8) {
      setDocVal('form-control is-invalid');
    } else {
      setDocVal('form-control');
    }
  };

  return loading ? (
    <Loading size="2x"></Loading>
  ) : entity ? (
    <div className="vehicles">
      <h3>Editar Veículo</h3>
      <form
        onSubmit={async (evt) => !loading && (await handleSubmit(evt))}
        className="card-style">
        <label htmlFor="inputType" className="">
        Tipo
        </label>
        <select
          value={(entity.type || '').toUpperCase()}
          onChange={(e) => setEntity({ ...entity, type: e.target.value })}
          id="inputType"
          className="form-control"
          placeholder="Tipo"
          required={true}
          autoFocus=""
        >
          <option value='' disabled>...</option>
          <option>TOCO</option>
          <option>TRUCK</option>
        </select>

        <label htmlFor="inputModel" className="mt-3">
          Modelo
        </label>
        <input
          value={(entity.model || '').toUpperCase()}
          onChange={(e) => setEntity({ ...entity, model: e.target.value })}
          type="text"
          id="inputModel"
          className="form-control"
          placeholder="Modelo"
          required={true}
          autoFocus=""
          maxLength="10"
        />

        <label htmlFor="inputPlate" className="mt-3">
          Placa
        </label>
        <InputMask
          value={(entity.plate || '').toUpperCase()}
          onBlur={(e) => checkDoc(e)}
          onChange={(e) => setEntity({...entity, plate: e.target.value})}
          className={docVal}
          maskChar={null}
          mask="aaa-9*99"
          type="text"
          id="inputPlate"
          placeholder="Placa"
          required={true}
          autoFocus=""
        />
        <div className="invalid-feedback">Placa Inválida</div>

        <label htmlFor="inputBulk0" className="mt-3">
          Tanque 1
        </label>
        <input
          value={entity.bulk0}
          onChange={(e) => setEntity({ ...entity, bulk0: +e.target.value })}
          type="text"
          id="inputBulk0"
          className="form-control"
          placeholder="Capacidade em Litros"
          required={true}
          autoFocus=""
          maxLength="5"
          onKeyPress={e => /[^0-9]$/.test(e.key) && e.preventDefault()}
          onPaste={e => e.preventDefault()}
        />

        <label htmlFor="inputBulk1" className="mt-3">
          Tanque 2
        </label>
        <input
          value={entity.bulk1}
          onChange={(e) => setEntity({ ...entity, bulk1: +e.target.value })}
          type="text"
          id="inputBulk1"
          className="form-control"
          placeholder="Capacidade em Litros"
          required={false}
          autoFocus=""
          maxLength="5"
          onKeyPress={e => /[^0-9]$/.test(e.key) && e.preventDefault()}
          onPaste={e => e.preventDefault()}
        />

        <label htmlFor="inputBulk2" className="mt-3">
          Tanque 3
        </label>
        <input
          value={entity.bulk2}
          onChange={(e) => setEntity({ ...entity, bulk2: +e.target.value })}
          type="text"
          id="inputBulk2"
          className="form-control"
          placeholder="Capacidade em Litros"
          required={false}
          autoFocus=""
          maxLength="5"
          onKeyPress={e => /[^0-9]$/.test(e.key) && e.preventDefault()}
          onPaste={e => e.preventDefault()}
        />

        <label htmlFor="inputBulkCap" className="mt-3">
          Total
        </label>
        <input
          value={entity.bulk0 + entity.bulk1 + entity.bulk2}
          type="number"
          id="inputBulkCap"
          className="form-control"
          placeholder="Capacidade Total do Tanque"
          disabled={true}
          autoFocus=""
        />

        <button
          disabled={loadingSave}
          className="btn btn-lg btn-primary btn-block mt-4 col-sm-12 col-md-4"
          type="submit">
          {loadingSave ? (
            <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
          ) : (
            'Salvar'
          )}
        </button>
      </form>
      <ConfirmationModal
        title={`Dados da placa incompletos`}
        message="Verificar dados"></ConfirmationModal>
    </div>
  ) : (
    <h4>Vehículo não encontrado</h4>
  );
};

import React, { useState } from "react";
import InputMask from 'react-input-mask';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import BrV from 'br-validations';
import { CONFIG } from "../../../helpers/_config"

export const FarmerMain = ({ upValidation, entity, setEntity }) => {
  const [maskPhone, setMaskPhone] = useState("(99)99999-9999");
  const [maskPhone2, setMaskPhone2] = useState("(99)99999-9999");
  const [maskDoc, setMaskDoc] = useState("999.999.999-999");
  const [docVal, setDocVal] = useState("form-control");
  const [ieVal, setIeVal] = useState("form-control");
  const [foneVal, setfoneVal] = useState({ className: 'form-control', required: false })

  const checkIe = (e) => {
    let ie = !entity.address.estate ? CONFIG.ufDefault : entity.address.estate;
    if (!BrV.ie(ie).validate(e.target.value)) {
      upValidation({ id: "brlIE", status: false });
      setIeVal("form-control is-invalid");
    } else {
      upValidation({ id: "brlIE", status: true });
      setIeVal("form-control");
    }
  }

  const checkDoc = (e) => {
    if (e.target.value.trim().length <= 14 && !cpf.isValid(e.target.value)) {
      upValidation({ id: "CPF/CNPJ", status: false });
      setDocVal("form-control is-invalid");
    } else if (e.target.value.trim().length > 14 && !cnpj.isValid(e.target.value)) {
      upValidation({ id: "CPF/CNPJ", status: false });
      setDocVal("form-control is-invalid");
    } else {
      upValidation({ id: "CPF/CNPJ", status: true });
      setDocVal("form-control");
    }
  }

  const checkFone = (fone) => {
    if (fone.length < 1) {
      upValidation({ id: "Telefone", status: true });
      setfoneVal({ required: false, className: 'form-control' });
    } else if (fone.length > 0 && fone.length < 13) {
      upValidation({ id: "Telefone", status: false });
      setfoneVal({ required: true, className: 'form-control is-invalid' });
    } else {
      upValidation({ id: "Telefone", status: true });
      setfoneVal({ required: true, className: 'form-control' });
    }
  }

  return (
    <div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="inputDoc" className="">
            CPF/CNPJ
          </label>
          <InputMask
            value={entity.brlCNPJCPF || ""}
            onBlur={(e) => checkDoc(e)}
            onChange={(e) => {
              setEntity({ ...entity, brlCNPJCPF: e.target.value });
              e.target.value.trim().length <= 14 ? setMaskDoc("999.999.999-999") : setMaskDoc("99.999.999/9999-99");
            }
            }
            className={docVal}
            maskChar={null}
            mask={maskDoc}
            type="text"
            id="inputDoc"
            placeholder="CPF ou CNPJ do Produtor"
            required={true}
            autoFocus=""
          />
          <div className="invalid-feedback">
            Documento Inválido
          </div>
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="inputDocIE" className="">
            Inscrição Estadual
          </label>
          <InputMask
            value={entity.brlIE || ""}
            onBlur={(e) => checkIe(e)}
            onChange={(e) => setEntity({ ...entity, brlIE: e.target.value })
            }
            className={ieVal}
            maskChar={null}
            mask="999.999.999.999999"
            type="text"
            id="inputDocIE"
            placeholder="Inscrição Estadual do Produtor"
          />
          <div className="invalid-feedback">
            Documento Inválido
      </div>
        </div>
      </div>
      <div className="form-row justify-content-md-center mt-3 mb-3">
        <div className="custom-control custom-checkbox">
          <input
            onChange={(e) =>
              setEntity({ ...entity, taxIncentive: e.target.checked })
            }
            className="custom-control-input"
            type="checkbox"
            id="taxIncentive"
            checked={entity.taxIncentive}
          />
          <label className="custom-control-label" htmlFor="taxIncentive">
            Optante Incentivo?
            </label>
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="inputName" className="">
            Nome
          </label>
          <input
            value={entity.name || ''}
            onChange={(e) => {
              setEntity({ ...entity, name: (e.target.value || "").toUpperCase() });
              }
            }
            type="text"
            id="inputName"
            className="form-control"
            placeholder="Nome"
            required={true}
            autoFocus=""
          />
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="inputDocId" className="">
            RG
          </label>
          <InputMask
            value={entity.brlRG || ""}
            onChange={(e) => setEntity({ ...entity, brlRG: e.target.value })}
            maskChar={null}
            mask="9999999999"
            type="text"
            id="inputDocId"
            className="form-control"
            placeholder="RG do Produtor"
          />
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="inputPhone" className="">
            Telefone
          </label>
          <InputMask
            value={entity.phone || ""}
            onBlur={(e) => checkFone(e.target.value)}
            onChange={(e) => {
              setEntity({ ...entity, phone: e.target.value });
              e.target.value.trim().length <= 13 ? setMaskPhone("(99)9999-99999") : setMaskPhone("(99)99999-9999")
            }
            }
            maskChar={null}
            mask={maskPhone}
            type="text"
            id="inputPhone"
            className={foneVal.className}
            placeholder="Telefone"
          />
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="inputPhone2" className="">
            Telefone2
          </label>
          <InputMask
            value={entity.phone2 || ""}
            onBlur={(e) => checkFone(e.target.value)}
            onChange={(e) => {
              setEntity({ ...entity, phone2: e.target.value });
              e.target.value.trim().length <= 13 ? setMaskPhone2("(99)9999-99999") : setMaskPhone2("(99)99999-9999")
            }
            }
            maskChar={null}
            mask={maskPhone2}
            type="text"
            id="inputPhone2"
            className={foneVal.className}
            placeholder="Telefone"
          />
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-6">
          <label htmlFor="inputEmail" className="">
            e-mail
          </label>
          <input
            value={entity.email || ""}
            onChange={(e) =>
              setEntity({ ...entity, email: e.target.value })
            }
            type="email"
            id="inputEmail"
            className="form-control"
            placeholder="e-mail do Produtor"
          />
        </div>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="form-group col-md-3">
          <label htmlFor="inputNIRF" className="">
            NIRF
          </label>
          <InputMask
            value={entity.brlNirf || ""}
            onChange={(e) =>
              setEntity({ ...entity, brlNirf: e.target.value })
            }
            mask={"999999999999"}
            maskChar={null}
            type="text"
            id="inputNIRF"
            className="form-control"
            placeholder="NIRF"
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputNRP" className="">
            NRP
          </label>
          <InputMask
            value={entity.brlNrp || ""}
            onChange={(e) =>
              setEntity({ ...entity, brlNrp: e.target.value })
            }
            mask={"999999999999"}
            maskChar={null}
            type="text"
            id="inputNRP"
            className="form-control"
            placeholder="NRP"
          />
        </div>
      </div>
    </div>
  );
};

import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {getTruckdrivers, deleteTruckdriver} from '../../../helpers/TruckDriver';
import {Loading} from '../../dumb/Loading/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {ConfirmationModal} from '../../dumb/ConfirmationModal/ConfirmationModal';
import { PaginationBar } from '../../dumb/PaginationBar/PaginationBar';
import { SearchBar } from '../../dumb/SearchBar/SearchBar';

export const TruckDriver = () => {
  // pagination config
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [tempSearch, setTempSearch] = useState('');
  const [totalItems, setTotalItems] = useState(null);
  const [search, setSearch] = useState('');
  useEffect(() => {
    (async () => {
      if (page) {
        await handleGettruckdriver(page, search);
      }
    })();
  }, [page, search]);

  const [entities, setEntities] = useState(null);
  const [loading, setLoading] = useState(true);
  const [truckdriverToDelete, settruckdriverToDelete] = useState({});
  const [loadingDelete, setLoadingDelete] = useState(false);
  let history = useHistory();
  let {path} = useRouteMatch();

  const handleGettruckdriver = async () => {
    setLoading(true);
    try {
      const {entities, totalItems, itemsPerPage} = await getTruckdrivers(
        page,
        search,
      );
      setEntities(entities);
      setTotalItems(totalItems);
      setItemsPerPage(itemsPerPage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoadingDelete(true);
      await deleteTruckdriver(id);
      await handleGettruckdriver();
      setLoadingDelete(false);
    } catch (error) {
      setLoadingDelete(false);
    }
  };

  const handleSearch = (evt) => {
    evt.preventDefault();
    setPage(1);
    setSearch(tempSearch);
  };

  return (
    <div className="page-container">
      <h3>Motoristas</h3>
      <div className="truckdriver-header text-right mb-4">
        <button
          className="btn btn-primary"
          onClick={() => history.push(`${path}/criar`)}>
          Adicionar Motorista
        </button>
      </div>
      <div className="card-style">
        {loading ? (
          <Loading size="2x"></Loading>
        ) : (
          <div>
              <SearchBar
              tempSearch={tempSearch}
              handleSearch={handleSearch}
              setSearch={(evt) => setTempSearch(evt.target.value)}></SearchBar>
            <table className="table table-striped table-bordered mt-4">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">CPF</th>
                  <th scope="col" className="text-center">
                    Remover
                  </th>
                </tr>
              </thead>
              <tbody>
                {(entities || []).map((truckdriver) => (
                  <tr className="clickable-row" key={truckdriver._id}>
                    <td
                      onClick={() =>
                        history.push(`${path}/${truckdriver._id}`)
                      }>
                      {truckdriver.name}
                    </td>
                    <td
                      onClick={() =>
                        history.push(`${path}/${truckdriver._id}`)
                      }>
                      {truckdriver.doc}
                    </td>
                    <td className="text-center no-hover">
                      {loadingDelete &&
                      truckdriverToDelete._id === truckdriver._id ? (
                        <Loading size="1x"></Loading>
                      ) : (
                        <FontAwesomeIcon
                          className="delete-button"
                          icon={faTrash}
                          data-toggle="modal"
                          data-target="#confirmationModal"
                          onClick={() =>
                            settruckdriverToDelete(truckdriver)
                          }></FontAwesomeIcon>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationBar page={page} setPage={setPage} totalItems={totalItems} itemsPerPage={itemsPerPage}></PaginationBar>
          </div>
        )}
      </div>
      <ConfirmationModal
        title={`Excluir Motorista ${
          truckdriverToDelete && truckdriverToDelete.name
        }?`}
        message="Esta ação não pode ser revertida"
        action={async () =>
          await handleDelete(truckdriverToDelete._id)
        }></ConfirmationModal>
    </div>
  );
};

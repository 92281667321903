import React from 'react';

export const SearchBar = ({tempSearch, handleSearch, setSearch}) => {

    return <form className="form-inline my-2 my-lg-0" onSubmit={handleSearch}>
        <div className="input-group mb-3">
        <input className="form-control" type="search" onChange={setSearch} placeholder="..." aria-label="Search" value={tempSearch}/>
        <div className="input-group-append">
        <button className="btn btn-outline-secondary" type="submit">Pesquisar</button>
        </div>
</div>
    </form>
}
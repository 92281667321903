import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createUser } from '../../../../helpers/User';

export const CreateUser = () => {
    const [entity, setEntity] = useState({ level: 'user' });
    const [loading, setLoading] = useState(false);
    let history = useHistory();

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        setLoading(true);
        try {
            await createUser(entity);
            setLoading(false);
            setTimeout(() => history.push('/portal/usuarios'), 200);
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <div className="page-container">
            <h3>Criar usuário</h3>
            <form onSubmit={async (evt) => !loading && await handleSubmit(evt)} className="card-style">
                <label htmlFor="inputName" className="">Nome</label>
                <input
                    value={(entity.name || '').toUpperCase()} onChange={e => setEntity({ ...entity, name: e.target.value })}
                    type="text" id="inputName" className="form-control" placeholder="Nome" required={true} autoFocus="" />

                <label htmlFor="inputEmail" className="mt-3">Email</label>
                <input
                    value={entity.email || ''} onChange={e => setEntity({ ...entity, email: e.target.value })}
                    type="email" id="inputEmail" className="form-control" placeholder="Email" required={true} autoFocus="" />

                <label htmlFor="inputPassword" className="mt-3">Senha</label>
                <input
                    value={entity.password || ''} onChange={e => setEntity({ ...entity, password: e.target.value })}
                    type="password" id="inputPassword" className="form-control" placeholder="Senha" required={true} autoFocus="" />


                <div className="form-check form-check-inline mt-3">
                    <input
                        onChange={e => setEntity({ ...entity, level: e.target.value })}
                        className="form-check-input" type="radio" name="exampleRadios" id="adminLevelRadio" value="admin" checked={entity.level === 'admin'} />
                    <label className="form-check-label" htmlFor="adminLevelRadio">
                        Admin
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        onChange={e => setEntity({ ...entity, level: e.target.value })}
                        className="form-check-input" type="radio" name="exampleRadios" id="userLevelRadio" value="manager" checked={entity.level === 'manager'} />
                    <label className="form-check-label" htmlFor="userLevelRadio">
                        Gerente
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        onChange={e => setEntity({ ...entity, level: e.target.value })}
                        className="form-check-input" type="radio" name="exampleRadios" id="userLevelRadio" value="user" checked={entity.level === 'user'} />
                    <label className="form-check-label" htmlFor="userLevelRadio">
                        Usuário
                    </label>
                </div>

                <button disabled={loading} className="btn btn-lg btn-primary btn-block mt-4 col-sm-12 col-md-4" type="submit">
                    {
                        loading ?
                            <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
                            :
                            'Criar'
                    }
                </button>
            </form>
        </div>
    )
}

import { axiosInstance } from './Interceptor';

export const getTrips = async (page = 1, search = '') => {
    try {
        const response = await axiosInstance.get(`trips?page=${page}&search=${search}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const getTrip = async (id) => {
    try {
        const response = await axiosInstance.get(`trips/${id}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const deleteTrip = async (id) => {
    try {
        const response = await axiosInstance.delete(`trips/${id}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export const editTrip = async (id, data) => {
    try {
        const response = await axiosInstance.put(`trips/${id}`, data);
        return response;
    } catch (err) {
        throw err;
    }
}

export const createTrip = async (data) => {
    try {
        const response = await axiosInstance.post(`trips`, data);
        return response;
    } catch (err) {
        throw err;
    }
}

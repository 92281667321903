import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { login, AuthContext } from '../../../helpers/Auth';
import './Login.css';
import { history } from '../../../App';
import { messageToast } from '../../dumb/MessageToast/MessageToast';
import logo from '../../../assets/logo.svg';

function LoginPage() {
    const { dispatch } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        setLoading(true);
        setError(false);
        try {
            const loginResponse = await login(email, password);
            setLoading(false);
            dispatch({
                type: "LOGIN",
                payload: loginResponse
            });
            history.push('/portal');
            setTimeout(() => messageToast('Bem vindo ao Sistema de Coleta', 'info'), 200);
        } catch (err) {
            setLoading(false);
            setError(true);
        }
    }

    return (
        <div className="Login">
            <form onSubmit={async (evt) => !loading && await handleSubmit(evt)} className="form-signin">
                <img src={logo} alt="colatte-logo" className="logo"/>
                <h1 className="h3 font-weight-normal login-title">Sistema de Coleta</h1>
                <label htmlFor="inputEmail" className="sr-only">Email</label>
                <input
                    value={email} onChange={e => setEmail(e.target.value)}
                    type="email" id="inputEmail" className="form-control" placeholder="Email" required={true} autoFocus="" />
                <label htmlFor="inputPassword" className="sr-only">Senha</label>
                <input
                    value={password} onChange={e => setPassword(e.target.value)}
                    type="password" id="inputPassword" className="form-control mt-2" placeholder="Senha" required={true} />
                <button disabled={loading} className="btn btn-lg btn-primary btn-block login-button" type="submit">
                    {
                        loading ?
                            <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
                            :
                            'Entrar'
                    }
                </button>
                {
                    error ?
                        <div className="alert alert-danger mt-2" role="alert">
                            Usuário ou senha incorretos
                        </div>
                        :
                        null
                }
                <p className="mt-5 mb-3 text-muted">© Colatte 2020</p>
            </form>
        </div>
    );
}

export default LoginPage;

export const BANKS = [
{cod:"654", name:"Banco A.J.Renner S.A."},
{cod:"246", name:"Banco ABC Brasil S.A."},
{cod:"075", name:"Banco ABN AMRO S.A."},
{cod:"121", name:"Banco Agibank S.A."},
{cod:"025", name:"Banco Alfa S.A."},
{cod:"641", name:"Banco Alvorada S.A."},
{cod:"065", name:"Banco Andbank (Brasil) S.A."},
{cod:"213", name:"Banco Arbi S.A."},
{cod:"096", name:"Banco B3 S.A."},
{cod:"024", name:"Banco BANDEPE S.A."},
{cod:"330", name:"Banco Bari de Investimentos e Financiamentos S/A"},
{cod:"318", name:"Banco BMG S.A."},
{cod:"752", name:"Banco BNP Paribas Brasil S.A."},
{cod:"107", name:"Banco BOCOM BBM S.A."},
{cod:"063", name:"Banco Bradescard S.A."},
{cod:"036", name:"Banco Bradesco BBI S.A."},
{cod:"122", name:"Banco Bradesco BERJ S.A."},
{cod:"204", name:"Banco Bradesco Cartões S.A."},
{cod:"394", name:"Banco Bradesco Financiamentos S.A."},
{cod:"237", name:"Banco Bradesco S.A."},
{cod:"218", name:"Banco BS2 S.A."},
{cod:"208", name:"Banco BTG Pactual S.A."},
{cod:"473", name:"Banco Caixa Geral - Brasil S.A."},
{cod:"412", name:"Banco Capital S.A."},
{cod:"040", name:"Banco Cargill S.A."},
{cod:"266", name:"Banco Cédula S.A."},
{cod:"739", name:"Banco Cetelem S.A."},
{cod:"233", name:"Banco Cifra S.A."},
{cod:"745", name:"Banco Citibank S.A."},
{cod:"241", name:"Banco Clássico S.A."},
{cod:"756", name:"Banco Cooperativo do Brasil S.A. - BANCOOB"},
{cod:"748", name:"Banco Cooperativo Sicredi S.A."},
{cod:"222", name:"Banco Credit Agricole Brasil S.A."},
{cod:"505", name:"Banco Credit Suisse (Brasil) S.A."},
{cod:"069", name:"Banco Crefisa S.A."},
{cod:"003", name:"Banco da Amazônia S.A."},
{cod:"083", name:"Banco da China Brasil S.A."},
{cod:"707", name:"Banco Daycoval S.A."},
{cod:"51", name:"Banco de Desenvolvimento do Espírito Santo S.A."},
{cod:"300", name:"Banco de La Nacion Argentina"},
{cod:"495", name:"Banco de La Provincia de Buenos Aires"},
{cod:"494", name:"Banco de La Republica Oriental del Uruguay"},
{cod:"001", name:"Banco do Brasil S.A."},
{cod:"047", name:"Banco do Estado de Sergipe S.A."},
{cod:"037", name:"Banco do Estado do Pará S.A."},
{cod:"041", name:"Banco do Estado do Rio Grande do Sul S.A."},
{cod:"004", name:"Banco do Nordeste do Brasil S.A."},
{cod:"265", name:"Banco Fator S.A."},
{cod:"224", name:"Banco Fibra S.A."},
{cod:"626", name:"Banco Ficsa S.A."},
{cod:"094", name:"Banco Finaxis S.A."},
{cod:"612", name:"Banco Guanabara S.A."},
{cod:"012", name:"Banco Inbursa S.A."},
{cod:"604", name:"Banco Industrial do Brasil S.A."},
{cod:"653", name:"Banco Indusval S.A."},
{cod:"077", name:"Banco Inter S.A."},
{cod:"249", name:"Banco Investcred Unibanco S.A."},
{cod:"184", name:"Banco Itaú BBA S.A."},
{cod:"029", name:"Banco Itaú Consignado S.A."},
{cod:"479", name:"Banco ItauBank S.A"},
{cod:"376", name:"Banco J. P. Morgan S.A."},
{cod:"074", name:"Banco J. Safra S.A."},
{cod:"217", name:"Banco John Deere S.A."},
{cod:"076", name:"Banco KDB S.A."},
{cod:"757", name:"Banco KEB HANA do Brasil S.A."},
{cod:"600", name:"Banco Luso Brasileiro S.A."},
{cod:"243", name:"Banco Máxima S.A."},
{cod:"720", name:"Banco Maxinvest S.A."},
{cod:"389", name:"Banco Mercantil do Brasil S.A."},
{cod:"370", name:"Banco Mizuho do Brasil S.A."},
{cod:"746", name:"Banco Modal S.A."},
{cod:"066", name:"Banco Morgan Stanley S.A."},
{cod:"456", name:"Banco MUFG Brasil S.A."},
{cod:"007", name:"Banco Nacional de Desenvolvimento Econômico e Social - BNDES"},
{cod:"169", name:"Banco Olé Bonsucesso Consignado S.A."},
{cod:"079", name:"Banco Original do Agronegócio S.A."},
{cod:"212", name:"Banco Original S.A."},
{cod:"712", name:"Banco Ourinvest S.A."},
{cod:"623", name:"Banco PAN S.A."},
{cod:"611", name:"Banco Paulista S.A."},
{cod:"643", name:"Banco Pine S.A."},
{cod:"658", name:"Banco Porto Real de Investimentos S.A."},
{cod:"747", name:"Banco Rabobank International Brasil S.A."},
{cod:"633", name:"Banco Rendimento S.A."},
{cod:"741", name:"Banco Ribeirão Preto S.A."},
{cod:"120", name:"Banco Rodobens S.A."},
{cod:"422", name:"Banco Safra S.A."},
{cod:"033", name:"Banco Santander (Brasil) S.A."},
{cod:"743", name:"Banco Semear S.A."},
{cod:"754", name:"Banco Sistema S.A."},
{cod:"630", name:"Banco Smartbank S.A."},
{cod:"366", name:"Banco Société Générale Brasil S.A."},
{cod:"637", name:"Banco Sofisa S.A."},
{cod:"464", name:"Banco Sumitomo Mitsui Brasileiro S.A."},
{cod:"082", name:"Banco Topázio S.A."},
{cod:"634", name:"Banco Triângulo S.A."},
{cod:"018", name:"Banco Tricury S.A."},
{cod:"655", name:"Banco Votorantim S.A."},
{cod:"610", name:"Banco VR S.A."},
{cod:"119", name:"Banco Western Union do Brasil S.A."},
{cod:"124", name:"Banco Woori Bank do Brasil S.A."},
{cod:"081", name:"BancoSeguro S.A."},
{cod:"021", name:"BANESTES S.A. Banco do Estado do Espírito Santo"},
{cod:"755", name:"Bank of America Merrill Lynch Banco Múltiplo S.A."},
{cod:"250", name:"BCV - Banco de Crédito e Varejo S.A."},
{cod:"144", name:"BEXS Banco de Câmbio S.A."},
{cod:"017", name:"BNY Mellon Banco S.A."},
{cod:"126", name:"BR Partners Banco de Investimento S.A."},
{cod:"070", name:"BRB - Banco de Brasília S.A."},
{cod:"92", name:"Brickell S.A. Crédito, Financiamento e Investimento"},
{cod:"104", name:"Caixa Econômica Federal"},
{cod:"114-7", name:"Central das Cooperativas de Economia e Crédito Mútuo do Estado do Espírito Santo Ltda."},
{cod:"320", name:"China Construction Bank (Brasil) Banco Múltiplo S.A."},
{cod:"477", name:"Citibank N.A."},
{cod:"163", name:"Commerzbank Brasil S.A. - Banco Múltiplo"},
{cod:"85", name:"Cooperativa Central de Crédito - AILOS"},
{cod:"97", name:"Cooperativa Central de Crédito Noroeste Brasileiro Ltda."},
{cod:"090-2", name:"Cooperativa Central de Economia e Crédito Mutuo - SICOOB UNIMAIS"},
{cod:"087-6", name:"Cooperativa Central de Economia e Crédito Mútuo das Unicreds de Santa Catarina e Paraná"},
{cod:"089-2", name:"Cooperativa de Crédito Rural da Região da Mogiana"},
{cod:"098-1", name:"CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL"},
{cod:"487", name:"Deutsche Bank S.A. - Banco Alemão"},
{cod:"64", name:"Goldman Sachs do Brasil Banco Múltiplo S.A."},
{cod:"78", name:"Haitong Banco de Investimento do Brasil S.A."},
{cod:"62", name:"Hipercard Banco Múltiplo S.A."},
{cod:"269", name:"HSBC Brasil S.A. - Banco de Investimento"},
{cod:"132", name:"ICBC do Brasil Banco Múltiplo S.A."},
{cod:"492", name:"ING Bank N.V."},
{cod:"139", name:"Intesa Sanpaolo Brasil S.A. - Banco Múltiplo"},
{cod:"652", name:"Itaú Unibanco Holding S.A."},
{cod:"341", name:"Itaú Unibanco S.A."},
{cod:"488", name:"JPMorgan Chase Bank, National Association"},
{cod:"399", name:"Kirton Bank S.A. - Banco Múltiplo"},
{cod:"128", name:"MS Bank S.A. Banco de Câmbio"},
{cod:"753", name:"Novo Banco Continental S.A. - Banco Múltiplo"},
{cod:"613", name:"Omni Banco S.A."},
{cod:"254", name:"Paraná Banco S.A."},
{cod:"125", name:"Plural S.A. - Banco Múltiplo"},
{cod:"751", name:"Scotiabank Brasil S.A. Banco Múltiplo"},
{cod:"118", name:"Standard Chartered Bank (Brasil) S/A–Bco Invest."},
{cod:"14", name:"State Street Brasil S.A. - Banco Comercial"},
{cod:"95", name:"Travelex Banco de Câmbio S.A."},
{cod:"129", name:"UBS Brasil Banco de Investimento S.A."},
{cod:"091-4", name:"Unicred Central do Rio Grande do Sul"},
{cod:"84", name:"Uniprime Norte do Paraná - Coop de Economia e Crédito Mútuo dos Médicos, Profissionais das Ciências"}];
